import React, { useEffect, useState } from 'react';
import { LoaderSpinner } from "./LoaderSpinner"
import { currenciesFullList } from '../../constants/index.js';

export const ActivitesListCard = ({ 
    activitesListLoading, 
    activitiesList, 
    bookingResult, 
    activitesListOpen, 
    setActivitesListOpen, 
    activityBookingResult, 
    searchInputedData, 
    setSearchInputedData, 
    getActivityDetails,
    activityDetailsOpen,
    setActivityDetailsOpen,
    setActivityDetails,
    setActivitySubmited,
    activitySubmited,
    activityDetails,
    activityBookingData,
    changePassengerData,
    bookActivity,
    bookActivityLoading,
    countriesList,
    setActivityBookingData,
    activityChildsBookingData,
    changePassengerChildData,
    resetActivityBooking,
}) => {
    // const removeAdult = (index) => {
    //     let copiedData = [...activityBookingData];
    //     copiedData.splice(index, 1);
    //     setActivityBookingData(copiedData);
    // }

    const [photoModalImage, setPhotoModalImage] = useState(false);

    return (
        <>
            { activitesListLoading ? 
                <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                    <LoaderSpinner />
                </div> : null
            }
            { activitiesList !== null && bookingResult === null ? 
                <>
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                        <a href="/#" className={`activites-list-open-btn ${activitesListOpen ? 'active' : ''}`} onClick={(e)=>{
                            e.preventDefault();
                            setActivitesListOpen(!activitesListOpen)
                        }}>
                            Activites List 
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.33301 9.16669L10.9997 12.8334L14.6663 9.16669" stroke="#323232" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </a>
                    </div>
                    { activitesListOpen ?
                    <div className="activities_list">
                        { activityBookingResult ? 
                            // activityBookingResult?.booking?.reference ? 
                            // <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "15px" }}>
                            //     <h3>Activity booked success</h3>
                            //     <h4 style={{ marginTop: "10px" }}>Reference: "{ activityBookingResult.booking.reference }" <strong>save this code</strong></h4>
                            // </div> : 
                            activityBookingResult?.confirmationNumber ? 
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "15px" }}>
                                <h3>Activity booked success</h3>
                                {/* <h4 style={{ marginTop: "10px" }}>Booking id: "{ activityBookingResult?.confirmationNumber }" <strong>save this code</strong></h4> */}
                                { activityBookingResult?._id ? <h4 style={{ marginTop: "10px" }}>
                                    Save this booking id: <strong><a href={`e-ticket.html?id=${activityBookingResult?._id}`} target='_blank'>{ activityBookingResult?._id }</a></strong></h4> 
                                : null }
                            </div> : 
                            
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "15px" }}>
                                <h3>Activity order was not successful</h3>
                                <h4 style={{ marginTop: "10px" }}>Please try again</h4>
                                <a
                                    href="#" className="btn btn-sm bg-secondary radius mt-15"
                                    onClick={(e)=>{
                                        e.preventDefault();
                                        resetActivityBooking();
                                    }}
                                >Try Again</a>
                            </div> : 
                        <>
                            <ul style={{ marginTop: activitiesList === null || activitiesList === false ? "0px" : "15px" }}>
                                { activitiesList === null || activitiesList === false ? 
                                    <li>Not Found</li> : 
                                    (searchInputedData.activity ? [searchInputedData.activity] : activitiesList).map((activity, index) => 
                                    <li key={index}>
                                        <a href="/#" onClick={(e)=>{
                                            e.preventDefault();
                                            setSearchInputedData((prevState)=>{
                                                return { ...prevState, activity }
                                            })
                                            getActivityDetails(activity);
                                        }}>{activity.name} 
                                            {/* <span>{
                                                activity?.currency && currenciesFullList[activity?.currency]?.symbol ? 
                                                    currenciesFullList[activity?.currency]?.symbol+(activity.options[0].totalPrice || 0) : 
                                                    currenciesFullList[activity?.currency]?.symbol ? 
                                                    currenciesFullList[activity?.currency]?.symbol+'0' : '0'
                                            }</span> */}
                                            <span>{
                                                activity?.options[0]?.rates[0]?.totalFares?.currency && currenciesFullList[activity?.options[0]?.rates[0]?.totalFares?.currency]?.symbol ? 
                                                    currenciesFullList[activity?.options[0]?.rates[0]?.totalFares?.currency]?.symbol+(activity?.options[0]?.rates[0]?.totalFares?.amount || 0) : 
                                                    currenciesFullList[activity?.options[0]?.rates[0]?.totalFares?.currency]?.symbol ? 
                                                    currenciesFullList[activity?.options[0]?.rates[0]?.totalFares?.currency]?.symbol+'0' : '0'
                                            }</span>
                                        </a>
                                    </li>)
                                }
                            </ul>
                            { searchInputedData.activity ? 
                                <div style={{ marginTop: "15px" }}>
                                    { searchInputedData.activity.content.description ?
                                        <>
                                            <a href="/#" className={`activites-list-open-btn ${activityDetailsOpen ? 'active' : ''}`} 
                                                onClick={(e)=>{
                                                    e.preventDefault();
                                                    setActivityDetailsOpen(!activityDetailsOpen)
                                                }}
                                                style={{ marginBottom: "10px" }}
                                            >
                                                Show details 
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.33301 9.16669L10.9997 12.8334L14.6663 9.16669" stroke="#323232" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg>
                                            </a>
                                            { activityDetailsOpen ? 
                                                <p dangerouslySetInnerHTML={{__html: searchInputedData.activity.content.description}} style={{ marginBottom: "10px" }}></p> 
                                            : null}
                                        </> : null
                                    }
                                    { searchInputedData.activity.content?.media?.images?.length ?
                                        <>
                                            <p className="mt-15"><strong>Photos:</strong></p>
                                            <div style={{ display: "flex", flexWrap: "wrap", width: "calc(100% + 10px)", margin: "0 -10px" }}>
                                                {searchInputedData.activity.content.media.images.map((item, index) => {
                                                    return <div className="activity-photo-item" key={index} onClick={(e)=>{
                                                        e.preventDefault();
                                                        setPhotoModalImage(item);
                                                    }}>
                                                        <img src={item} alt="" />
                                                    </div>
                                                }) }
                                            </div>
                                        </>
                                    : null }
                                </div> : null 
                            }
                            {
                                searchInputedData.activity && !activitySubmited ?
                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "15px" }}>
                                    <a href="/#" className="btn btn-sm bg-secondary radius"
                                        style={{ minWidth: "140px", background: "rgb(255, 255, 255)", color: "rgb(34, 106, 114)", border: "1px solid rgb(232, 232, 232)" }}
                                        onClick={(e)=>{
                                            e.preventDefault();
                                            setSearchInputedData((prevState)=>{
                                                return { ...prevState, activity: null }
                                            });
                                            setActivityDetails(false);
                                        }}
                                    >Back</a>
                                    <a href="/#" className="btn btn-sm bg-secondary radius" onClick={(e)=> {
                                        e.preventDefault();
                                        setActivitySubmited(true);
                                    }}>Submit activity</a>
                                </div> : null
                            }
                            {
                                (searchInputedData.activity && activityDetails) && activitySubmited ?
                                <>
                                    <div className="form-data-box mt-15">
                                        <h3 style={{ marginBottom: "10px" }}>Adults:</h3>
                                        { activityBookingData.map((passenger, itemDataIndex)=>{
                                            return <div key={itemDataIndex} style={{ marginBottom: "15px", paddingBottom: "15px", borderBottom: "1px solid rgb(232, 232, 232)" }}>
                                                <div className="form-group-columns">
                                                    <div className="form-group">
                                                        <label>Title:</label>
                                                        <select
                                                            className="input type-2"
                                                            value={ passenger.title }
                                                            onInput={(e)=>{
                                                                changePassengerData(itemDataIndex, "title", e.target.value)
                                                            }}
                                                        >
                                                            <option value="Mr">Mr</option>
                                                            <option value="Mrs">Mrs</option>
                                                            <option value="Ms">Ms</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Email:</label>
                                                        <input 
                                                            type="text" className="input type-2" placeholder="email"
                                                            value={ passenger.email }
                                                            onInput={(e)=>{
                                                                changePassengerData(itemDataIndex, "email", e.target.value)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group-columns">
                                                    <div className="form-group">
                                                        <label>First Name:</label>
                                                        <input 
                                                            type="text" className="input type-2" placeholder="first name"
                                                            value={ passenger.firstName }
                                                            onInput={(e)=>{
                                                                changePassengerData(itemDataIndex, "firstName", e.target.value)
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Last Name:</label>
                                                        <input
                                                            type="text" className="input type-2" placeholder="last name"
                                                            value={ passenger.lastName }
                                                            onInput={(e)=>{
                                                                changePassengerData(itemDataIndex, "lastName", e.target.value)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group-columns">
                                                    <div className="form-group">
                                                        <label>Address:</label>
                                                        <input 
                                                            type="text" className="input type-2" placeholder="address"
                                                            value={ passenger.address }
                                                            onInput={(e)=>{
                                                                changePassengerData(itemDataIndex, "address", e.target.value)
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>zipcode:</label>
                                                        <input
                                                            type="text" className="input type-2" placeholder="zipcode"
                                                            value={ passenger.zipcode }
                                                            onInput={(e)=>{
                                                                changePassengerData(itemDataIndex, "zipcode", e.target.value)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group-columns">
                                                    <div className="form-group">
                                                        <label>telephone:</label>
                                                        <input 
                                                            type="text" className="input type-2" placeholder="telephone"
                                                            value={ passenger.telephone }
                                                            onInput={(e)=>{
                                                                changePassengerData(itemDataIndex, "telephone", e.target.value)
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Country:</label>
                                                        <select
                                                            className="input type-2"
                                                            value={ passenger.countryCode }
                                                            onInput={(e)=>{
                                                                changePassengerData(itemDataIndex, "countryCode", e.target.value)
                                                            }}
                                                        >
                                                            {
                                                                countriesList.map(country => {
                                                                    return <option key={country.country} value={country.country}>{country.name}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group-columns">
                                                    <div className="form-group">
                                                        <label>Date of Birth:</label>
                                                        <input 
                                                            type="date" className="input type-2" placeholder="Date of Birth"
                                                            value={ passenger.dob }
                                                            onInput={(e)=>{
                                                                changePassengerData(itemDataIndex, "dob", new Date(e.target.value).toISOString().slice(0, 10))
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div style={{ display: "flex", alignItems: "flex-start", margin: "15px 0", gap: "10px", borderBottom: "1px solid rgb(232, 232, 232)", paddingBottom: "15px" }}>
                                                    { activityBookingData.length > 1 ? 
                                                        <a href="/#" className="btn btn-sm bg-secondary radius" 
                                                            style={{ minWidth: "140px", background: "rgb(255, 255, 255)", color: "rgb(34, 106, 114)", border: "1px solid rgb(232, 232, 232)" }}
                                                            onClick={(e)=> {
                                                                e.preventDefault();
                                                                removeAdult(itemDataIndex);
                                                            }}
                                                        >Remove Adult</a>
                                                    : null }
                                                    { itemDataIndex === activityBookingData.length-1 ?
                                                    <a href="/#" className="btn btn-sm bg-secondary radius" style={{ minWidth: "140px" }} onClick={(e)=> {
                                                        e.preventDefault();
                                                        setActivityBookingData((prevState)=> {
                                                            return [...prevState, {
                                                                "title": "Mr",
                                                                "firstName": "",
                                                                "lastName": "",
                                                                "email": "",
                                                                "address": "",
                                                                "zipcode": "",
                                                                "telephone": "",
                                                                "countryCode": passenger.countryCode
                                                            }]
                                                        })
                                                    }}>Add Adult</a> : null }
                                                </div> */}
                                            </div> })
                                        }
                                    </div>
                                    {
                                        activityChildsBookingData?.length ? 
                                        <div className="form-data-box mt-15">
                                            <h3 style={{ marginBottom: "10px" }}>Childs:</h3>
                                            { activityChildsBookingData.map((passenger, itemDataIndex)=>{
                                                return <div key={itemDataIndex} style={{ marginBottom: "15px", paddingBottom: "15px", borderBottom: "1px solid rgb(232, 232, 232)" }}>
                                                    <div className="form-group-columns">
                                                        <div className="form-group">
                                                            <label>Title:</label>
                                                            <select
                                                                className="input type-2"
                                                                value={ passenger.title }
                                                                onInput={(e)=>{
                                                                    changePassengerChildData(itemDataIndex, "title", e.target.value)
                                                                }}
                                                            >
                                                                <option value="Mr">Mr</option>
                                                                <option value="Mrs">Mrs</option>
                                                                <option value="Ms">Ms</option>
                                                            </select>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Age:</label>
                                                            <input 
                                                                type="text" className="input type-2 disabled" disabled={true}
                                                                value={ searchInputedData.child_ages[itemDataIndex] }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group-columns">
                                                        <div className="form-group">
                                                            <label>First Name:</label>
                                                            <input 
                                                                type="text" className="input type-2" placeholder="first name"
                                                                value={ passenger.firstName }
                                                                onInput={(e)=>{
                                                                    changePassengerChildData(itemDataIndex, "firstName", e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Last Name:</label>
                                                            <input
                                                                type="text" className="input type-2" placeholder="last name"
                                                                value={ passenger.lastName }
                                                                onInput={(e)=>{
                                                                    changePassengerChildData(itemDataIndex, "lastName", e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                        <div className="form-group-columns">
                                                            <div className="form-group-columns">
                                                            <div className="form-group">
                                                                <label>Date of Birth:</label>
                                                                <input 
                                                                    type="date" className="input type-2" placeholder="Date of Birth"
                                                                    value={ passenger.dob }
                                                                    onInput={(e)=>{
                                                                        changePassengerChildData(itemDataIndex, "dob", new Date(e.target.value).toISOString().slice(0, 10))
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div> })
                                            }
                                        </div> : null
                                    }
                                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "15px" }}>
                                        <a href="/#" className="btn btn-sm bg-secondary radius" 
                                            style={{ minWidth: "140px", background: "rgb(255, 255, 255)", color: "rgb(34, 106, 114)", border: "1px solid rgb(232, 232, 232)" }}
                                            onClick={(e)=> {
                                                e.preventDefault();
                                                setActivitySubmited(false);
                                            }}
                                        >Back</a>
                                        <a href="/#" className="btn btn-sm bg-secondary radius" style={{ minWidth: "140px" }} onClick={(e)=> {
                                            e.preventDefault();
                                            bookActivity();
                                        }}>
                                            { bookActivityLoading ? 
                                                <div style={{ display: "flex", justifyContent: "center", filter: "brightness(0) invert(1)", zoom: "0.6" }}>
                                                    <LoaderSpinner />
                                                </div> : 'Book activity'
                                            }
                                        </a>
                                    </div>
                                </> : null
                            }
                        </> }
                    </div> : null }
                </>
            : null }
            <div className={`modal photo-modal-overlay ${photoModalImage ? 'modal--show' : ''}`} onClick={(e)=>{
				e.preventDefault();
				setPhotoModalImage(false);
			}}>
				<div className="modal__dialog modal__dialog--500">
					<div className="modal__content">
						<div className="modal__header">
							<div></div>
							<a href="#" className="btn btn-close" data-close-modal="" onClick={(e)=>{
								e.preventDefault();
								setPhotoModalImage(false);
							}}>
								<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M5 5L25 25" stroke="#002E50" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M25 5L5 25" stroke="#002E50" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
								</svg>
							</a>
						</div>
						<div className="modal__body">
							{ photoModalImage ? <img src={photoModalImage} alt="" /> : null }
						</div>
					</div>
				</div>
			</div>
        </>
    );
}
import React, { useEffect, useState } from 'react';

export const StaysBookingCard = ({ 
		searchInputedData,
		stays,
		staysPage,
		staysAllRates,
		setStaysAllRates,
		setStaysPage,
		createQuoteForRate,
		quoteForRate,
		setQuoteForRate,
		setBookingData,
		staysFetchAllRates,
		hotelSubmited,
		setHotelSubmited,
	}) => {
    const [hotelOrderData, setHotelOrderData] = useState({
        "quote_id": quoteForRate?.id, 
        "phone_number": "+442080160509",
        "guests": [
            {
                "given_name": "Amelia",
                "family_name": "Earheart",
                "born_on": "1987-07-24"
            }
        ],
        "email": "amelia.earheart@duffel.com",
        "accommodation_special_requests": "2:00 PM early check-in required"
    });

	const [photoModalImage, setPhotoModalImage] = useState(false);

    const changeGuestData = (index, field_name, value) => {
		let copiedHotelOrderData = JSON.parse(JSON.stringify(hotelOrderData));
        copiedHotelOrderData.guests[index][field_name] = value;
        setHotelOrderData(copiedHotelOrderData);
    }

	const currencySymbol = (value) => {
		let symbolData = {
			'USD': '$',
			'EUR': '€'
		}
		return symbolData[value] ? symbolData[value] : value;
	}

	useEffect(() => {
		if(quoteForRate?.id && (hotelOrderData.quote_id !== quoteForRate?.id)){
			setHotelOrderData((prevState)=>{
				return {
					...prevState,
					quote_id: quoteForRate?.id,
				}
			})
		}
	}, [quoteForRate])

	useEffect(() => {
		if(+searchInputedData.adults !== hotelOrderData.guests.length){
			setHotelOrderData((prevState)=>{
				return {
					...prevState,
					"guests": new Array(+searchInputedData.adults).fill({
						"given_name": "",
						"family_name": "",
						"born_on": ""
					}),
				}
			});
		}
	}, [])

    return (
        <>
            {
                searchInputedData.hotels_resorts && stays.length && !staysAllRates ? <div className="card"><div className="card-body p-4">
                    <ul style={{ display: "flex", flexWrap: "wrap", gap: "20px", width: "100%" }}>
                    {
                        (stays.length > 16 ? stays.slice(staysPage > 1 ? Math.ceil(16*(staysPage-1)) : 0, Math.ceil(16*staysPage)) : stays).map((item, index) => {
                            return <li key={index+item.id} className="order-form-stays-card">
                                <div className="mb-15">
                                    <div className="stay-photo">
                                        { item.accommodation.photos.length ?
                                            <img src={item.accommodation.photos[0].url} alt="" />
                                        : 'No photo' }
                                    </div>
                                    <h4 style={{ fontSize: "16px" }} className="mb-15">{item.accommodation.name}</h4>
                                    <div>
                                        <div className="d-flex align-items-center">
											<b>Rating:</b> 
											<ul className="rating-stars ml-10">
												{
													new Array(5).fill('').map((itemRating, itemRatingIndex)=>{
														return <li key={itemRatingIndex}>
															<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M7.00002 1.16699L8.80252 4.81866L12.8334 5.40783L9.91669 8.24866L10.605 12.262L7.00002 10.3662L3.39502 12.262L4.08335 8.24866L1.16669 5.40783L5.19752 4.81866L7.00002 1.16699Z" fill={ 
																	(item?.accommodation?.ratings?.[0]?.value >= itemRatingIndex+1) ? '#226A72' : '#AFB1B6' 
																	} />
															</svg>
														</li>
													})
												}
											</ul>
										</div>
                                        <div>
											<b>Address:</b> <span>{ item.accommodation.location.address.city_name }, { item.accommodation.location.address.line_one }, { item.accommodation.location.address.postal_code }</span>
										</div>
                                        <div><b>Price:</b> {currencySymbol(item.accommodation.cheapest_rate_currency)}{item.accommodation.cheapest_rate_total_amount}</div>
                                        <div>
                                            <a href={`https://www.google.com/maps/place/${item.accommodation.name}/@${item.accommodation.location.geographic_coordinates.latitude},${item.accommodation.location.geographic_coordinates.longitude},18z`} target="_blank" style={{ display: "flex", alignItems: "center", textDecoration: "underline" }}>
                                                See on map
                                                <svg width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"  className="ml-10">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M20 21.6666V21.6666C17.2383 21.6666 15 19.4283 15 16.6666V16.6666C15 13.905 17.2383 11.6666 20 11.6666V11.6666C22.7617 11.6666 25 13.905 25 16.6666V16.6666C25 19.4283 22.7617 21.6666 20 21.6666Z" stroke="#226A72" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M20 35C20 35 8.33333 25.4167 8.33333 16.6667C8.33333 10.2233 13.5567 5 20 5C26.4433 5 31.6667 10.2233 31.6667 16.6667C31.6667 25.4167 20 35 20 35Z" stroke="#226A72" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                
                                <a
                                    href="/#" className="btn btn-sm bg-secondary radius"
                                    style={{ marginTop: "auto" }}
                                    onClick={(e)=>{
                                        e.preventDefault();
                                        staysFetchAllRates(item.id);
                                    }}
                                >
                                    Stay Here
                                </a>
                            </li>
                        })
                    }
                    </ul>
                    <ul className="form-pagination mt-15">
                        { stays.length ?
                            new Array(Math.ceil(stays.length / 16)).fill('').map((item, index)=>{
                                return <li key={index}>
                                    <a 
                                        href="#" 
                                        onClick={(e)=>{
                                            e.preventDefault();
                                            setStaysPage(index+1);
                                        }}
                                        className={staysPage === index+1 ? `active` : ''}
                                    >{index+1}</a>
                                </li>
                            }) : null
                        }
                    </ul>
                </div></div> : staysAllRates ? 
                <div className="card">
                    <div className="card-body p-4">
                        <h3 style={{ fontSize: "20px", marginBottom: "15px" }}>
                            { quoteForRate ? 'Selected hotel' : 'Hotel'}: {staysAllRates.accommodation.name} 
                            <a href={`https://www.google.com/maps/place/${staysAllRates.accommodation.name}/@${staysAllRates.accommodation.location.geographic_coordinates.latitude},${staysAllRates.accommodation.location.geographic_coordinates.longitude},18z`} target="_blank">
                                <svg width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M20 21.6666V21.6666C17.2383 21.6666 15 19.4283 15 16.6666V16.6666C15 13.905 17.2383 11.6666 20 11.6666V11.6666C22.7617 11.6666 25 13.905 25 16.6666V16.6666C25 19.4283 22.7617 21.6666 20 21.6666Z" stroke="#226A72" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M20 35C20 35 8.33333 25.4167 8.33333 16.6667C8.33333 10.2233 13.5567 5 20 5C26.4433 5 31.6667 10.2233 31.6667 16.6667C31.6667 25.4167 20 35 20 35Z" stroke="#226A72" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </a>
                        </h3>
                        <div style={{ fontSize: "14px", lineHeight: "18px", background: "rgb(249, 249, 249)", padding: "12px 16px", borderRadius: "8px" }}>
							<div>
								<b>Address:</b> <span>{ staysAllRates.accommodation.location.address.city_name }, { staysAllRates.accommodation.location.address.line_one }, { staysAllRates.accommodation.location.address.postal_code }</span>
							</div>
                            <div><b className="fsz-12">Check In Date:</b> <span>{ new Date(staysAllRates.check_in_date).toLocaleDateString() }</span></div>
                            <div><b className="fsz-12">Check Out Date:</b> <span>{ new Date(staysAllRates.check_out_date).toLocaleDateString() }</span></div>
                            <div><b className="fsz-12">Price:</b> <span>{ currencySymbol(staysAllRates.accommodation.cheapest_rate_currency) }{ staysAllRates.accommodation.cheapest_rate_total_amount }</span></div>
                            <div><b className="fsz-12">Description:</b> <span>{ staysAllRates.accommodation.description }</span></div>
							<div className="d-flex align-items-center">
								<b className="fsz-12">Rating:</b> 
								<ul className="rating-stars ml-10">
									{
										new Array(5).fill('').map((itemRating, itemRatingIndex)=>{
											return <li key={itemRatingIndex}>
												<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M7.00002 1.16699L8.80252 4.81866L12.8334 5.40783L9.91669 8.24866L10.605 12.262L7.00002 10.3662L3.39502 12.262L4.08335 8.24866L1.16669 5.40783L5.19752 4.81866L7.00002 1.16699Z" fill={ staysAllRates.accommodation.ratings[0].value >= itemRatingIndex+1 ? '#226A72' : '#AFB1B6' } />
												</svg>
											</li>
										})
									}
								</ul>
							</div>
                            <div><b className="fsz-12">Photos:</b></div>
                            <div className="hotel-photos-wrpr">
                                { staysAllRates.accommodation.photos.slice(0, 10).map((photoItem, photoItemIndex)=>{
                                    return <div
                                        key={photoItemIndex}
                                        className="hotel-photo-item"
										onClick={(e)=>{
											e.preventDefault();
											setPhotoModalImage(photoItem.url);
										}}
                                    >
                                        <img src={photoItem.url} alt="" />
                                    </div>
                                }) }
                            </div>

                            <h4 className="mt-15">{ quoteForRate ? 'Selected room' : 'Rooms'}:</h4>
                            {
								// .filter(roomItem => roomItem.rates[0].payment_type === 'pay_now')
                                (quoteForRate ? quoteForRate.accommodation.rooms : staysAllRates.accommodation.rooms).map((roomItem, roomItemIndex)=>{
                                    return <div className="mb-15" key={roomItemIndex}>
                                        <div><b className="fsz-12">Name:</b> <span>{ roomItem.name }</span></div>
                                        <div><b className="fsz-12">Base Price:</b> <span>{ currencySymbol(roomItem.rates[0].base_currency) }{ roomItem.rates[0].base_amount }</span></div>
                                        <div><b className="fsz-12">Tax:</b> <span>{ currencySymbol(roomItem.rates[0].tax_currency) }{ roomItem.rates[0].tax_amount }</span></div>
                                        <div><b className="fsz-12">Total Price:</b> <span>{ currencySymbol(roomItem.rates[0].total_currency) }{ roomItem.rates[0].total_amount }</span></div>
                                        { roomItem?.rates[0]?.payment_type ?
											<div><b className="fsz-12">Payment Type:</b> <span>{ roomItem.rates[0].payment_type }</span></div>
										: null }
                                        { !quoteForRate ? <div className="mt-15">
												<a 
													href="/#" className="btn btn-sm bg-secondary radius"
													onClick={(e)=>{
														e.preventDefault();
														createQuoteForRate(roomItem.rates[0].id, roomItem.rates[0]);
													}}
												>Select Room</a>
											</div> : 
											// ORDER DATA FORM
											<div className="hotel-offer-item-form" style={{ pointerEvents: hotelSubmited ? 'none' : 'initial' }}>
												<h3>Guests:</h3>
												{ hotelOrderData.guests.map((guest, guestIndex)=>{
													return <div key={ guestIndex }>
														<h4>Guest { guestIndex+1 }:</h4>
														<div className="form-group-columns">
															<div className="form-group">
																<label>First Name:</label>
																<input
																	type="text" className="input type-2" placeholder="First Name"
																	value={ guest.family_name }
																	onInput={(e)=>{
																		changeGuestData(guestIndex, "family_name", e.target.value);
																	}} 
																/>
															</div>
															<div className="form-group">
																<label>Last Name:</label>
																<input
																	type="text" className="input type-2" placeholder="Last Name"
																	value={ guest.given_name }
																	onInput={(e)=>{
																		changeGuestData(guestIndex, "given_name", e.target.value);
																	}} 
																/>
															</div>
														</div>
														<div className="form-group-columns">
														<div className="form-group">
																<label>Born on:</label>
																<input
																	type="text" className="input type-2" placeholder="Born on"
																	value={ guest.born_on }
																	onInput={(e)=>{
																		changeGuestData(guestIndex, "born_on", e.target.value);
																	}} 
																/>
															</div>
														</div>
													</div>
												})}
												<h3>Contacts:</h3>
												<div className="form-group-columns">
													<div className="form-group">
														<label>Email:</label>
														<input
															type="text" className="input type-2" placeholder="Email"
															value={ hotelOrderData.email }
															onInput={(e)=>{
																setHotelOrderData((prevState)=> {
																	return {
																		...prevState,
																		email: e.target.value,
																	}
																});
															}}
														/>
													</div>
													<div className="form-group">
														<label>Phone number:</label>
														<input
															type="text" className="input type-2" placeholder="Phone number"
															value={ hotelOrderData.phone_number }
															onInput={(e)=>{
																setHotelOrderData((prevState)=> {
																	return {
																		...prevState,
																		phone_number: e.target.value,
																	}
																});
															}}
														/>
													</div>
												</div>
												<h3>Additional information:</h3>
												<div className="form-group-columns">
													<div className="form-group">
														<label>Accommodation special requests:</label>
														<input
															type="text" className="input type-2" placeholder="Accommodation special requests"
															value={ hotelOrderData.accommodation_special_requests }
															onInput={(e)=>{
																setHotelOrderData((prevState)=> {
																	return {
																		...prevState,
																		accommodation_special_requests: e.target.value,
																	}
																});
															}}
														/>
													</div>
												</div>
												{ !hotelSubmited ? <div className="form-group-columns">
													<div className="form-group double-btns-line-wrpr" style={{
														
													}}>
														<a
															href="/#" className="btn btn-sm bg-secondary radius btn-min-w-140"
															style={{
																background: "#FFFFFF",
																color: "#226A72",
																border: "1px solid #e8e8e8"
															}} 
															onClick={(e)=>{
																e.preventDefault();
																setQuoteForRate(null);
															}}
														>Back</a>
														<a href="/#" className="btn btn-sm bg-secondary radius btn-min-w-140" onClick={(e)=>{
															e.preventDefault();
															setBookingData("hotel", hotelOrderData);
															setHotelSubmited(true);
														}}>Submit</a>
													</div>
												</div> : null }
											</div>
                                        }
                                    </div>
                                })
                            }
                        </div>
						{ staysAllRates && !quoteForRate ? <div className="mt-15">
							<a
								href="/#" className="btn btn-sm bg-secondary radius btn-min-w-140"
								style={{
									background: "#FFFFFF",
									color: "#226A72",
									border: "1px solid #e8e8e8"
								}} 
								onClick={(e)=>{
									e.preventDefault();
									setStaysAllRates(null);
								}}
							>Back</a>
						</div> : null }
                    </div>
                </div>
             : null
            }
			<div className={`modal photo-modal-overlay ${photoModalImage ? 'modal--show' : ''}`} onClick={(e)=>{
				e.preventDefault();
				setPhotoModalImage(false);
			}}>
				<div className="modal__dialog modal__dialog--500">
					<div className="modal__content">
						<div className="modal__header">
							<div></div>
							<a href="#" className="btn btn-close" data-close-modal="" onClick={(e)=>{
								e.preventDefault();
								setPhotoModalImage(false);
							}}>
								<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M5 5L25 25" stroke="#002E50" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M25 5L5 25" stroke="#002E50" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
								</svg>
							</a>
						</div>
						<div className="modal__body">
							{ photoModalImage ? <img src={photoModalImage} alt="" /> : null }
						</div>
					</div>
				</div>
			</div>
        </>
    );
}
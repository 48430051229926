import React, { useState, useEffect } from 'react';
import { apiUrl } from "../config/index.js"
import { Card } from './Card';
import { GENERIC_ERROR_MESSAGE } from './constants';
import Select from 'react-select';
import { useDebounce } from "../helpers/useDebounce";

// Free account token pk.73fd73be485a2607469e45185aadc99b
const token = 'pk.73fd73be485a2607469e45185aadc99b';

const getAutoComplete = async(q) => {
    const url = `https://api.locationiq.com/v1/autocomplete?key=${token}&q=${q}&limit=5&dedupe=1&tag=place:city&accept-language=en`;
    return await fetch(url).then(res => res.json())
}

let timer;


export const SearchCard = ({ inputedData, setInputedData, beforeSearch, onSuccess, onError, data }) => {
    const [autocomplete, setAutocomplete] = useState([]);

    let town = (data && data.address && data.address) ? (data.address.town || data.address.city) : '';
    const [sort, setSort] = useState('total_duration');
    const [origin, setOrigin] = useState('');
    const [destination, setDestination] = useState('');
    const [isFetching, setIsFetching] = useState(false);

    const [fromSearch, setFromSearch] = useState(town);
    const [toSearch, setToSearch] = useState('');
    const [fromAirports, setFromAirports] = useState([]);
    const [toAirports, setToAirports] = useState([]);

    const [updatedTimesFrom, setUpdatedTimesFrom] = useState(0);
    const isUpdatedFrom = useDebounce(updatedTimesFrom, 1000);
    const [updatedTimesTo, setUpdatedTimesTo] = useState(0);
    const isUpdatedTo = useDebounce(updatedTimesTo, 1000);

    const selectClassNames = {
        control: (state) => 'form-search-select-default',
        dropdownIndicator: (state) => 'form-search-select-dropdownindicator',
        indicatorSeparator: (state) => 'form-search-select-indicator-separator',
        menu: (state) => 'form-search-select-menu',
        menuList: (state) => 'form-search-select-menu-list',
        option: (state) => {
            return state.isSelected ? 'form-search-select-option is-selected' : state.isFocused ? 'form-search-select-option is-focused' : 'form-search-select-option'
        },
    }
    const applyResult = async(value) => {
        if(timer) clearTimeout(timer);
        timer = setTimeout(async() => {
            const res = await getAutoComplete(value);
            setAutocomplete(res);
        }, 600)
    }

    const fromSearchInputValue = (value, clear=false) => {
        setFromSearch(value);
        setUpdatedTimesFrom((prevState) => prevState + 1);

        if(!clear) {
            applyResult(value);
        } else {
            setAutocomplete([])
        }       
    }
    const toSearchInputValue = (value) => {
        setToSearch(value);
        setUpdatedTimesTo((prevState) => prevState + 1);
    }

    const searchFromTo = async(type) => {
        console.log('searchFrom start');
        try {
            const res = await fetch(`${apiUrl}/duffel/places/suggestions?query=${type === 'from' ? fromSearch : toSearch}`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            const { data } = await res.json();

            if(data.length){
                let airportFilterMapped = data.filter(item => item.type === "airport").map((airport)=>{
                    return { value: airport.iata_code, label: airport.name }
                });

                if(type === 'from'){
                    setFromAirports(airportFilterMapped);
                    setOrigin(airportFilterMapped[0].value);
                } else {
                    setToAirports(airportFilterMapped);
                    setDestination(airportFilterMapped[0].value);
                }
            }
        } catch (e) {
            onError(e instanceof Error ? e : new Error(GENERIC_ERROR_MESSAGE));
        }
    }

    const fetchOffers = async() => {
        beforeSearch();
        setIsFetching(true);

        try {
            const res = await fetch(`${apiUrl}/duffel/search`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    origin,
                    destination,
                    sort,
                }),
            });

            const { offer, errors } = await res.json();

            if (errors) {
                onError(
                    new Error(
                        Array.isArray(errors) ? errors[0].title : GENERIC_ERROR_MESSAGE
                    )
                );
                return;
            }

            if (!offer) {
                onError(new Error(GENERIC_ERROR_MESSAGE));
                return;
            }

            onSuccess(offer);
        } catch (e) {
            onError(e instanceof Error ? e : new Error(GENERIC_ERROR_MESSAGE));
        }

        setIsFetching(false);
    };

    useEffect(() => {
        if(isUpdatedFrom){
            searchFromTo('from');
        }
    }, [isUpdatedFrom]);

    useEffect(() => {
        if(isUpdatedTo){
            searchFromTo('to');
        }
    }, [isUpdatedTo]);

    useEffect(() => {
        if((inputedData?.country_to?.length && inputedData?.city?.length) && (inputedData?.duffel?.destination === '' || inputedData?.duffel?.destination !== destination)){
            setToSearch(`${inputedData.country_to}, ${inputedData.city}`);
            setUpdatedTimesTo((prevState) => prevState + 1);
        }
    }, [inputedData]);

    useEffect(() => {
        setInputedData({
            ...inputedData,
            duffel: {
                sort: sort,
                origin: origin,
                destination: destination,
            }
        })
    }, [sort, origin, destination]);


    return (
        <>
            {/* <h2>1/3 Let’s make a simple search.</h2> */}

            <Card.Root>
                <Card.Content>
                    <div className="text-align-left card__content_column">
                        <Card.Text color="light">Next available:</Card.Text>
                        <Select
                            defaultValue={{ value: 'total_duration', label: 'Shortest flight' }}
                            placeholder="Next available"
                            classNames={selectClassNames}
                            options={[
                                { value: 'total_amount', label: 'Cheapest flight' },
                                { value: 'total_duration', label: 'Shortest flight' },
                            ]}
                            onChange={(option) => setSort(option.value)}
                        />
                    </div>
                    <div className="text-align-left card__content_column" style={{ position: 'relative' }}>
                        <Card.Text color="light">Origin:</Card.Text>
                        <input
                            type="text" className="input" placeholder="from"
                            value={fromSearch} onInput={(e)=>{fromSearchInputValue(e.target.value)}}
                        />
                        { !!autocomplete.length && 
                            <ul className="autocomplete-list card-white-shadow">
                                {
                                    autocomplete.map((ac, i) => {
                                        return (
                                            <li key={i} onClick={(e) => fromSearchInputValue(ac.display_place, true)}>
                                                <b>{ac.display_place}</b>
                                                <span>{ac.display_address}</span>
                                            </li>
                                        )
                                    })
                                }
                                
                            </ul>
                        }
                    </div>
                    { fromAirports.length ?
                    <div className="text-align-left card__content_column">
                        <Card.Text color="light">From airport:</Card.Text>
                        <Select
                            defaultValue={fromAirports.find(item => item.value === origin)}
                            placeholder="Next available"
                            classNames={selectClassNames}
                            options={fromAirports}
                            onChange={(option) => setOrigin(option.value)}
                        />
                    </div> : null }
                    <div className="text-align-left card__content_column">
                        <Card.Text color="light">Destination:</Card.Text>
                        <input
                            type="text" className="input" placeholder="to"
                            value={toSearch} onInput={(e)=>{toSearchInputValue(e.target.value)}}
                        />
                    </div>
                    { toAirports.length ?
                    <div className="text-align-left card__content_column">
                        <Card.Text color="light">To airport:</Card.Text>
                        <Select
                            defaultValue={toAirports.find(item => item.value === destination)}
                            placeholder="Next available"
                            classNames={selectClassNames}
                            options={toAirports}
                            onChange={(option) => setDestination(option.value)}
                        />
                    </div> : null }
                </Card.Content>
                {/* <div>
                    <Card.Text color="light"> </Card.Text>
                    <Card.Button onClick={fetchOffers} disabled={isFetching}>
                        {isFetching ? 'Searching…' : 'Search'}
                    </Card.Button>
                </div> */}
            </Card.Root>
        </>
    );
};

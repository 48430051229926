import React, { useState } from 'react';
import { SearchCard } from './components/SearchCard-old';
import { BookingCard } from './components/BookingCard-old';
import ConfirmationCard from './components/ConfirmationCard';

// import './index.css';

const ErrorDisplay = ({ error }) => (
  <div className="error">{error.message}</div>
);

function App({geo, data}) {
    console.log('App geo', geo, data);
    const [offer, setOffer] = useState(null);
    const [order, setOrder] = useState(null);

    const hasOffer = offer && typeof offer === 'object' && !(offer instanceof Error);
    const hasOrder = order && typeof order === 'object' && !(order instanceof Error);

    return (
        <div className="page">
            <div className="example-flow">
                <div className="container">
                    { !offer && (
                        <SearchCard
                            data={data}
                            beforeSearch={() => setOrder(null)}
                            onSuccess={(offer) => setOffer(offer)}
                            onError={(e) => setOffer(e)}
                        />
                    ) }
                    { hasOffer && !hasOrder && (
                        <BookingCard
                            offer={offer}
                            onSuccess={(order) => {
                                setOrder(order);
                            }}
                            onError={(e) => {
                                setOrder(e);
                                setOffer(null);
                            }}
                        />
                    ) }
                    { hasOffer && hasOrder && <ConfirmationCard order={order} /> }
                    { offer instanceof Error && <ErrorDisplay error={offer} /> }
                    { order instanceof Error && <ErrorDisplay error={order} /> }
                    { offer && (
                        <button
                            className="example-flow__reset"
                            onClick={() => {
                                setOrder(null);
                                setOffer(null);
                            }}
                        >
                            Start new search
                        </button>
                    ) }
                </div>
            </div>
        </div>
    );
}

export default App;

import React from 'react';
import { Card } from './Card';
import { formatCurrency, getAirlineLogoUrl } from '../utils';

function ConfirmationCard({ order }){
    console.log('ConfirmationCard order:', order);

    return (<>
        {/* <h2>3/3 Your order was created.</h2> */}
        <div className="card" style={{ minHeight: "0" }}>
            <h3 style={{ fontSize: "20px" }}>Flight booking result:</h3>
        </div>
        <Card.Root style={{ minHeight: "0" }}>
            <Card.Content>
                <img
                    src={getAirlineLogoUrl(order.owner.iata_code)}
                    alt={order.owner.name}
                    width={24}
                    height={24}
                />
                {/* <Card.Text color="dark">{order.owner.name}</Card.Text>
                <Card.Text className="offer-currency" color="dark">
                    {formatCurrency(order.total_amount, order.total_currency)}
                </Card.Text> */}
                <span className="card__text card__text--dark" style={{ marginBottom: '0px' }}>{order.owner.name}</span>
            
                <span className="card__text card__text--dark airway-card-info-item">
                    Departing at:
                    <span style={{ fontWeight: "400" }}>{ new Date(order.slices[0].segments[0].departing_at).toLocaleString() }</span>
                </span>
                <span className="card__text card__text--dark airway-card-info-item">
                    Arriving at:
                    <span style={{ fontWeight: "400" }}>{ new Date(order.slices[0].segments[0].arriving_at).toLocaleString() }</span>
                </span>

                <span className="card__text card__text--dark airway-card-info-item">
                    Origin:
                    <span style={{ fontWeight: "400" }}>{ order.slices[0].segments[0].origin.iata_code }</span>
                </span>
                <span className="card__text card__text--dark airway-card-info-item">
                    Destination:
                    <span style={{ fontWeight: "400" }}>{ order.slices[0].segments[0].destination.iata_code }</span>
                </span>
                
                <span className="card__text card__text--dark offer-currency">
                    <b>{formatCurrency(order.total_amount, order.total_currency)}</b>
                    <span className="card__text card__text--dark airway-card-info-item">
                        Class:
                        <span style={{ fontWeight: "400" }}>{ 
                            order.slices[0].segments[0].passengers[0]?.cabin_class_marketing_name ?
                            order.slices[0].segments[0].passengers[0].cabin_class_marketing_name :
                            order.slices[0].segments[0].passengers[0].cabin_class.split('_').join(' ')
                        }</span>
                    </span>
                </span>
            </Card.Content>
            {/* <Card.Button disabled={true} secondary>
                Booked!
            </Card.Button> */}
            <button
                className="card__btn card__btn--secondary" disabled
                style={{ marginBottom: '0px', pointerEvents: "none", opacity: "0.7" }}
            >Booked!</button>
        </Card.Root>

        {/* <p style={{ textAlign: "center" }}>Booking reference: <strong>{order.booking_reference}</strong></p> */}
    </>)
};


export default ConfirmationCard;
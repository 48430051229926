import React, { useState, useEffect } from 'react';
import { Card } from './Card';
import { GENERIC_ERROR_MESSAGE } from './constants';

import { useDebounce } from "../helpers/useDebounce";

export const SearchCard = ({ beforeSearch, onSuccess, onError }) => {
    const [sort, setSort] = useState('total_duration');
    const [origin, setOrigin] = useState('');
    const [destination, setDestination] = useState('');
    const [isFetching, setIsFetching] = useState(false);

    const [fromSearch, setFromSearch] = useState('');
    const [toSearch, setToSearch] = useState('');
    const [fromAirports, setFromAirports] = useState([]);
    const [toAirports, setToAirports] = useState([]);

    const [updatedTimesFrom, setUpdatedTimesFrom] = useState(0);
    const isUpdatedFrom = useDebounce(updatedTimesFrom, 1000);
    const [updatedTimesTo, setUpdatedTimesTo] = useState(0);
    const isUpdatedTo = useDebounce(updatedTimesTo, 1000);

    const fromSearchInputValue = (value) => {
        setFromSearch(value);
        setUpdatedTimesFrom((prevState) => prevState + 1);
    }
    const toSearchInputValue = (value) => {
        setToSearch(value);
        setUpdatedTimesTo((prevState) => prevState + 1);
    }

    const searchFromTo = async(type) => {
        console.log('searchFrom start');
        try {
            const res = await fetch(`https://app.ivacay.co/api/duffel/places/suggestions?query=${type === 'from' ? fromSearch : toSearch}`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            const { data } = await res.json();

            if(data.length){
                let airportFilterMapped = data.filter(item => item.type === "airport").map((airport)=>{
                    return { value: airport.iata_code, label: airport.name }
                });

                if(type === 'from'){
                    setFromAirports(airportFilterMapped);
                    setOrigin(airportFilterMapped[0].value);
                } else {
                    setToAirports(airportFilterMapped);
                    setDestination(airportFilterMapped[0].value);
                }
            }
        } catch (e) {
            onError(e instanceof Error ? e : new Error(GENERIC_ERROR_MESSAGE));
        }
    }

    const fetchOffers = async() => {
        beforeSearch();
        setIsFetching(true);

        try {
            const res = await fetch('https://app.ivacay.co/api/duffel/search', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    origin,
                    destination,
                    sort,
                }),
            });

            const { offer, errors } = await res.json();

            if (errors) {
                onError(
                    new Error(
                        Array.isArray(errors) ? errors[0].title : GENERIC_ERROR_MESSAGE
                    )
                );
                return;
            }

            if (!offer) {
                onError(new Error(GENERIC_ERROR_MESSAGE));
                return;
            }

            onSuccess(offer);
        } catch (e) {
            onError(e instanceof Error ? e : new Error(GENERIC_ERROR_MESSAGE));
        }

        setIsFetching(false);
    };

    useEffect(() => {
        if(isUpdatedFrom){
            searchFromTo('from');
        }
    }, [isUpdatedFrom]);

    useEffect(() => {
        if(isUpdatedTo){
            searchFromTo('to');
        }
    }, [isUpdatedTo]);

    return (
        <>
            <h2>1/3 Let’s make a simple search.</h2>

            <Card.Root>
                <Card.Content>
                    <div className="text-align-left card__content_column">
                        <Card.Text color="light">Next available:</Card.Text>
                        <Card.Select
                            onSelect={(option) => setSort(option)}
                            defaultValue={sort}
                            options={[
                                { value: 'total_amount', label: 'Cheapest flight' },
                                { value: 'total_duration', label: 'Shortest flight' },
                            ]}
                        />
                    </div>
                    <div className="text-align-left card__content_column">
                        <Card.Text color="light">From:</Card.Text>
                        <input
                            type="text" className="input type-2" placeholder="from"
                            value={fromSearch} onInput={(e)=>{fromSearchInputValue(e.target.value)}}
                        />
                    </div>
                    { fromAirports.length ?
                    <div className="text-align-left card__content_column">
                        <Card.Text color="light">From airport:</Card.Text>
                        <Card.Select
                            onSelect={setOrigin}
                            defaultValue={origin}
                            options={fromAirports}
                        />
                    </div> : null }
                    <div className="text-align-left card__content_column">
                        <Card.Text color="light">To:</Card.Text>
                        <input
                            type="text" className="input type-2" placeholder="to"
                            value={toSearch} onInput={(e)=>{toSearchInputValue(e.target.value)}}
                        />
                    </div>
                    { toAirports.length ?
                    <div className="text-align-left card__content_column">
                        <Card.Text color="light">To airport:</Card.Text>
                        <Card.Select
                            onSelect={setDestination}
                            defaultValue={destination}
                            options={toAirports}
                        />
                    </div> : null }
                </Card.Content>

                <div>
                    <Card.Text color="light"> </Card.Text>
                    <Card.Button onClick={fetchOffers} disabled={isFetching}>
                        {isFetching ? 'Searching…' : 'Search'}
                    </Card.Button>
                </div>
            </Card.Root>
        </>
    );
};

import React, { useState } from 'react';
import { Card } from './Card';
import { formatCurrency, getAirlineLogoUrl } from '../utils';
import { GENERIC_ERROR_MESSAGE } from './constants';

export const BookingCard = ({ offer, onSuccess, onError }) => {
    const [isFetching, setIsFetching] = useState(false);
    const [bookStep, setBookStep] = useState(1);
    const [passengers, setPassengers] = useState([
        {
            id: offer.passengers[0].id,
            born_on: '1987-07-24',
            family_name: 'Earheart',
            given_name: 'Amelia',
            gender: 'f',
            title: 'ms',
            email: 'amelia@duffel.com',
            phone_number: '+442080160509',
        },
    ]);

    const bookOffer = async () => {
        setIsFetching(true);

        const res = await fetch(`https://app.ivacay.co/api/duffel/book`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                offerId: offer.id,
                passengers: passengers,
                currency: offer.total_currency,
                amount: offer.total_amount,
            }),
        });

        const { order, errors } = await res.json();

        setIsFetching(false);

        if (Array.isArray(errors)) {
            onError(new Error(errors[0].title));
            return;
        }

        if (!order) {
            onError(new Error(GENERIC_ERROR_MESSAGE));
            return;
        }

        onSuccess(order);
    };

    return (
        <>
            <h2>2/3 We have a result, now let’s book it.</h2>

            <Card.Root>
                <Card.Content>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', flex: '1 1 auto' }}>
                            <img
                                src={getAirlineLogoUrl(offer.owner.iata_code)}
                                alt={offer.owner.name}
                                width={24}
                                height={24}
                            />
                            <Card.Text color="dark">{offer.owner.name}</Card.Text>
                            <Card.Text className="offer-currency" color="dark">
                                <b>{formatCurrency(offer.total_amount, offer.total_currency)}</b>
                            </Card.Text>
                        </div>
                        {bookStep === 2 ?
                        <>
                            {
                                passengers.map((passenger, index)=>{
                                    return <div style={{ marginTop: "6px", paddingTop: "10px", borderTop: "1px dashed rgba(226, 226, 232, 1)" }}>
                                        { index === 0 ? <h3 style={{ textAlign: "left", marginBottom: "15px", fontSize: "18px" }}>Passengers:</h3> : null }
                                        <div className="form-group-columns">
                                            <div className="form-group">
                                                <label>Family name:</label>
                                                <input type="text" className="input type-2" placeholder="family name" value={ passenger.family_name } onInput={(e)=>{
                                                    setPassengers((prevState)=>{
                                                        return {
                                                            ...prevState,
                                                            family_name: e.target.value
                                                        }
                                                    })
                                                }} />
                                            </div>
                                            <div className="form-group">
                                                <label>Given name:</label>
                                                <input type="text" className="input type-2" placeholder="given name" value={ passenger.given_name } onInput={(e)=>{
                                                    setPassengers((prevState)=>{
                                                        return {
                                                            ...prevState,
                                                            given_name: e.target.value
                                                        }
                                                    })
                                                }} />
                                            </div>
                                        </div>
                                        <div className="form-group-columns">
                                            <div className="form-group">
                                                <label>Born on:</label>
                                                <input type="date" className="input type-2" placeholder="born date" value={ passenger.born_on } onInput={(e)=>{
                                                    console.log('born_on:', e);
                                                    // setPassengers((prevState)=>{
                                                    //     return {
                                                    //         ...prevState,
                                                    //         born_on: e.target.value
                                                    //     }
                                                    // })
                                                }} />
                                            </div>
                                            <div className="form-group">
                                                <label>Gender:</label>
                                                <select className="input type-2" onInput={(e)=>{
                                                    console.log('born_on:', e);
                                                }}>
                                                    <option value="f">Female</option>
                                                    <option value="m">Male</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group-columns">
                                            <div className="form-group">
                                                <label>Email:</label>
                                                <input type="text" className="input type-2" placeholder="email" value={ passenger.email } onInput={(e)=>{
                                                    setPassengers((prevState)=>{
                                                        return {
                                                            ...prevState,
                                                            email: e.target.value
                                                        }
                                                    })
                                                }} />
                                            </div>
                                            <div className="form-group">
                                                <label>Phone number:</label>
                                                <input type="text" className="input type-2" placeholder="phone number" value={ passenger.phone_number } onInput={(e)=>{
                                                    setPassengers((prevState)=>{
                                                        return {
                                                            ...prevState,
                                                            phone_number: e.target.value
                                                        }
                                                    })
                                                }} />
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                            
                            <div style={{ flex: '1', marginTop: "10px", paddingTop: "10px", borderTop: "1px dashed rgba(226, 226, 232, 1)" }}>
                                <Card.Button
                                    disabled={isFetching}
                                    onClick={async () => await bookOffer()}
                                >
                                    {isFetching ? 'Booking…' : 'Book flight'}
                                </Card.Button>
                            </div>
                        </> : null }
                    </div>
                    

                </Card.Content>
                {bookStep === 1 ?
                <Card.Button
                    disabled={isFetching}
                    onClick={()=>{setBookStep(2)}}
                >
                    Book
                </Card.Button> : null }
            </Card.Root>
        </>
    );
};

import React, { useEffect, useState } from 'react';
import ConfirmationCard from './components/ConfirmationCard';
import { apiUrl } from "./config/index.js"

function AppETicket() {
    // 655227c8469f2f7b0c56677f
    const eTicketFormPage = window.location?.pathname?.includes('e-ticket-form');
    const mob = document.querySelector('body').offsetWidth < 575;

    const searchIdParam = new URLSearchParams(window.location.search).get('id');
    const searchUserIdParam = new URLSearchParams(window.location.search).get('user_id');

    const [searchValue, setSearchValue] = useState('');
    const [bookingResult, setBookingResult] = useState(null);
    console.log('bookingResult:', bookingResult);

    const [userId, setUserId] = useState(null);
    console.log('userId:', userId);

    const [savedBookingIdsList, setsavedBookingIdsList] = useState([]);
    
    const [showAccommodationsTerms, setShowAccommodationsTerms] = useState(false);

    const fetchBooking = async(id) => {
        let res = await fetch(`${apiUrl}/bookings/${id}`, {
            method: 'GET',
            redirect: 'follow'
        })
        .then(response => response.json())
        .then(result => {
            return result
        }).catch(error => console.log('error', error));

        if(res?.data){
            setBookingResult({
                ...res.data,
                _id: res?._id || ''
            });
        }
    }

    const saveToList = () => {
        let gettedStorage = localStorage.getItem('booking-list-ids');

        if(gettedStorage){
            gettedStorage = JSON.parse(gettedStorage);
        } else {
            gettedStorage = [];
        }

        gettedStorage.push(bookingResult._id);
        localStorage.setItem('booking-list-ids', JSON.stringify(gettedStorage));

        let list = localStorage.getItem('booking-list-ids');
        setsavedBookingIdsList(JSON.parse(list));
    }

    const getCurrencySymbol = (value) => {
        return value === 'USD' ? '$' : value;
    }

    const getBooksByUserId = async() => {
        let res = await fetch(`${apiUrl}/bookings-list/${userId || searchUserIdParam}`, {
            method: 'GET',
            redirect: 'follow'
        }).then(response => response.json()).then(result => {
            return result
        }).catch(error => console.log('error', error));

        setsavedBookingIdsList(res?.map(item => item?._id))
    }

    useEffect(()=>{
        if(eTicketFormPage){
            getBooksByUserId();

            return;
        }

        let list = localStorage.getItem('booking-list-ids');

        if(list) {
            setsavedBookingIdsList(JSON.parse(list));
        }
    }, []);

    useEffect(()=>{
        if(searchIdParam){
            fetchBooking(searchIdParam);
        }
    }, [searchIdParam]);

    useEffect(()=>{
        if(searchUserIdParam && !userId){
            setUserId(searchUserIdParam);
        }
    }, [searchUserIdParam]);
    

    return (
        <section className="search-section combined-platforms w-100">
            <div className="container relative">
                <div className={`card ${!eTicketFormPage ? 'card-white-shadow' : ''}`}>
                    <div className={`card-body booking-result-card-body ${!eTicketFormPage ? 'p-4' : ''}`}>
                        {
                            bookingResult ? <a href="#" className="app-e-ticket-back" onClick={(e)=>{
                                e.preventDefault();
                                setBookingResult(null);
                                getBooksByUserId();
                            }}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 18L9 12L15 6" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </a> : null
                        }
                        <h3 style={eTicketFormPage ? { marginBottom: "10px", padding: "0 15px", fontSize: "20px" } : { marginBottom: "10px" }} className="text-center">E-ticket{ bookingResult ? `: ${bookingResult._id}` : '' }</h3>
                        { bookingResult ? <>
                            {
                                bookingResult?.hotel?.accommodation?.location ? <div
                                    className="duffel-app" 
                                    style={{
                                        borderBottom: bookingResult?.flight ? "1px dashed rgba(226, 226, 232, 1)" : "0",
                                        paddingBottom: bookingResult?.flight ? "10px" : "0",
                                        marginBottom: bookingResult?.flight ? "10px" : "0",
                                    }}
                                >
                                    <h3 className="card-result-main-title" style={{ fontSize: "20px", marginBottom: "10px" }}>Hotel booking result:</h3>
                                    <ul>
                                        <li
                                            style={{
                                                fontSize: "14px",
                                                lineHeight: "18px",
                                                background: "#F9F9F9",
                                                padding: "12px 16px",
                                                borderRadius: "8px"
                                            }}
                                        >
                                            <div>
                                                <div>
                                                    <b className="fsz-12">Reference:</b> <span>{ bookingResult.hotel.reference }</span>
                                                </div>
                                                <div><b className="fsz-12">Rooms:</b> <span>{ bookingResult.hotel.accommodation?.rooms?.length }</span></div>
                                                <div><b className="fsz-12">Guests:</b> <span>{ bookingResult.hotel.guests?.length }</span></div>
                                                <div>
                                                    <b className="fsz-12">Confirmed:</b> <span>{ new Date(bookingResult.hotel.confirmed_at).toLocaleDateString() } at { new Date(bookingResult.hotel.confirmed_at).toLocaleTimeString() }</span>
                                                </div>

                                                <hr style={{ height: "1px", background: "#efefef", margin: "10px 0" }} />
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    { bookingResult?.hotel?.accommodation?.photos?.[0]?.url ? <div style={{ marginRight: '15px', display: 'flex', alignItems: 'center' }}>
                                                        <img src={ bookingResult.hotel.accommodation.photos[0].url } alt="" style={{ maxWidth: "120px", maxHeight: "120px" }} />
                                                    </div> : null }
                                                    <div>
                                                        <div>
                                                            <b className="fsz-12">Name:</b> <span>{ bookingResult.hotel.accommodation.name }</span>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <b className="fsz-12">Rating:</b> 
                                                            <ul className="rating-stars ml-10">
                                                                {
                                                                    new Array(5).fill('').map((itemRating, itemRatingIndex)=>{
                                                                        return <li key={itemRatingIndex}>
                                                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M7.00002 1.16699L8.80252 4.81866L12.8334 5.40783L9.91669 8.24866L10.605 12.262L7.00002 10.3662L3.39502 12.262L4.08335 8.24866L1.16669 5.40783L5.19752 4.81866L7.00002 1.16699Z" fill={ bookingResult.hotel.accommodation.ratings[0].value >= itemRatingIndex+1 ? '#226A72' : '#AFB1B6' } />
                                                                            </svg>
                                                                        </li>
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                        <div>
                                                            <b className="fsz-12">Address:</b> <span>{ bookingResult.hotel.accommodation.location.address.city_name }, { bookingResult.hotel.accommodation.location.address.line_one }, { bookingResult.hotel.accommodation.location.address.postal_code }</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <hr style={{ height: "1px", background: "#efefef", margin: "10px 0" }} />

                                                <div><b className="fsz-12">Check In Date:</b> <span>{new Date(bookingResult.hotel.check_in_date).toLocaleDateString()}</span></div>
                                                <div><b className="fsz-12">Check Out Date:</b> <span>{new Date(bookingResult.hotel.check_out_date).toLocaleDateString()}</span></div>

                                                <hr style={{ height: "1px", background: "#efefef", margin: "10px 0" }} />

                                                {/* <div><b className="fsz-12">Price:</b> <span>{bookingResult.hotel.accommodation.rooms[0].rates[0].total_currency}{bookingResult.hotel.accommodation.rooms[0].rates[0].total_amount}</span></div> */}
                                                <div><b className="fsz-12">Description:</b> <span>{bookingResult.hotel.accommodation.description}</span></div>

                                                <hr style={{ height: "1px", background: "#efefef", margin: "10px 0" }} />

                                                <h3 style={{ fontSize: "18px" }}>Cancellation policy:</h3>
                                                
                                                {
                                                    bookingResult.hotel.accommodation.rooms[0]?.rates[0]?.cancellation_timeline?.map((item, index)=>{
                                                        return <div key={index} style={{ marginBottom: "5px" }}>
                                                            {
                                                                +item.refund_amount === +bookingResult.hotel.accommodation.rooms[0].rates[0].total_amount &&
                                                                <span><b style={{ display: "inlineFlex", alignItems: "center" }}>
                                                                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "5px" }}>
                                                                        <line x1="0.707107" y1="5.29289" x2="5.70711" y2="10.2929" stroke="#68B954" stroke-width="2"/>
                                                                        <line x1="14.7071" y1="0.707106" x2="4.70712" y2="10.7071" stroke="#68B954" stroke-width="2"/>
                                                                    </svg>
                                                                    Full refund
                                                                </b> - You may cancel for free before { new Date(item.before).toLocaleDateString() }, {new Date(item.before).toLocaleTimeString().slice(0, 5)}. You will be refunded the full amount.</span>
                                                            }
                                                            {
                                                                +item.refund_amount !== +bookingResult.hotel.accommodation.rooms[0].rates[0].total_amount &&
                                                                <span><b style={{ display: "inlineFlex", alignItems: "center" }}>
                                                                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "5px" }}>
                                                                        <line x1="0.707107" y1="5.29289" x2="5.70711" y2="10.2929" stroke="#EFBF13" stroke-width="2"/>
                                                                        <line x1="14.7071" y1="0.707106" x2="4.70712" y2="10.7071" stroke="#EFBF13" stroke-width="2"/>
                                                                    </svg>
                                                                    Partial refund
                                                                </b> - If you cancel before { new Date(item.before).toLocaleDateString() }, {new Date(item.before).toLocaleTimeString().slice(0, 5)} you will be refunded {getCurrencySymbol(item.currency)}{item.refund_amount}.</span>
                                                            }
                                                        </div>
                                                    })
                                                }
                                                {
                                                    bookingResult.hotel.accommodation.rooms[0]?.rates[0]?.cancellation_timeline?.length ? 
                                                    <div style={{ marginBottom: "5px" }}>
                                                        <span><b style={{ display: "inlineFlex", alignItems: "center" }}>
                                                            <svg width="16" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "5px" }}>
                                                                <line x1="1.70711" y1="1.29289" x2="11.6568" y2="11.2426" stroke="#C61924" stroke-width="2"/>
                                                                <line x1="1.29289" y1="11.2426" x2="11.2426" y2="1.2929" stroke="#C61924" stroke-width="2"/>
                                                            </svg>
                                                            No refund
                                                        </b> - After { new Date(bookingResult.hotel.check_in_date).toLocaleDateString() }, {new Date(bookingResult.hotel.check_in_date).toLocaleTimeString().slice(0, 5)}, you will not be refunded</span>
                                                    </div> : <div style={{ marginBottom: "5px" }}>
                                                        <b>Non refundable</b>
                                                    </div>
                                                }

                                                <div>
                                                    <a href="#" style={{ textDecoration: "underline" }} onClick={(e)=>{
                                                        e.preventDefault();
                                                        setShowAccommodationsTerms((prevState) => !prevState);
                                                    }}><b className="fsz-12">{showAccommodationsTerms ? 'Close' : 'Read'} accommodation's terms and conditions</b></a>
                                                </div>

                                                { showAccommodationsTerms ? 
                                                    bookingResult.hotel.accommodation.rooms[0].rates[0]?.conditions?.map((item, index)=>{
                                                        return <div key={index}><b className="fsz-12">{ item?.title }:</b> <span dangerouslySetInnerHTML={{__html: item?.description }}></span></div>
                                                    }) : null
                                                }

                                                <hr style={{ height: "1px", background: "#efefef", margin: "10px 0" }} />
                                                
                                                <h3 style={{ fontSize: "18px" }}>Room:</h3>
                                                <div><b className="fsz-12">Name:</b> <span>{ bookingResult.hotel.accommodation.rooms[0].name }</span></div>
                                                <div><b className="fsz-12">Base Price:</b> <span>{ getCurrencySymbol(bookingResult.hotel.accommodation.rooms[0].rates[0].base_currency) }{ bookingResult.hotel.accommodation.rooms[0].rates[0].base_amount }</span></div>
                                                <div><b className="fsz-12">Tax:</b> <span>{ getCurrencySymbol(bookingResult.hotel.accommodation.rooms[0].rates[0].tax_currency) }{ bookingResult.hotel.accommodation.rooms[0].rates[0].tax_amount }</span></div>
                                                <div><b className="fsz-12">Fee amount:</b> <span>{ getCurrencySymbol(bookingResult.hotel.accommodation.rooms[0].rates[0].fee_currency) }{ bookingResult.hotel.accommodation.rooms[0].rates[0].fee_amount }</span></div>
                                                <div><b className="fsz-12">Total Price:</b> <span>{ getCurrencySymbol(bookingResult.hotel.accommodation.rooms[0].rates[0].total_currency) }{ bookingResult.hotel.accommodation.rooms[0].rates[0].total_amount }</span></div>
                                                <div><b className="fsz-12">Due At Accommodation:</b> <span>{ getCurrencySymbol(bookingResult.hotel.accommodation.rooms[0].rates[0].due_at_accommodation_currency) }{ bookingResult.hotel.accommodation.rooms[0].rates[0].due_at_accommodation_amount || "0.00" }</span></div>

                                                <hr style={{ height: "1px", background: "#efefef", margin: "10px 0" }} />

                                                <div>
                                                    <a href="terms-and-conditions.html" target="_blank" style={{ textDecoration: "underline" }}><b className="fsz-12">Terms and conditions</b></a>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div> : null
                            }
                            { bookingResult?.flight ? <div
                                className="duffel-app"
                                style={{
                                    // borderBottom: bookingResult?.activity ? "1px dashed rgba(226, 226, 232, 1)" : "0",
                                    // paddingBottom: bookingResult?.activity ? "10px" : "0",
                                    // marginBottom: bookingResult?.activity ? "10px" : "0",
                                    borderBottom: "0",
                                    paddingBottom: mob ? "10px" : "0",
                                    marginBottom: "0",
                                }}
                            >
                                <ConfirmationCard order={bookingResult?.flight} />
                            </div> : null }
                            {/* { bookingResult?._id ?
                                <p style={{ textAlign: "center", marginTop: "10px" }}>Save this booking id: <strong><a href="">{ bookingResult?._id }</a></strong></p> : null
                            } */}
                            {/* {
                                bookingResult?.activity ? <div
                                    className="duffel-app" 
                                    style={{ marginBottom: "10px" }}
                                >
                                    <h3 className="card-result-main-title" style={{ fontSize: "20px", marginBottom: "10px" }}>Activity booking result:</h3>
                                    <ul>
                                        <li
                                            style={{
                                                fontSize: "14px",
                                                lineHeight: "18px",
                                                background: "#F9F9F9",
                                                padding: "12px 16px",
                                                borderRadius: "8px"
                                            }}
                                        >
                                            <div>
                                                <div>
                                                    <b>Status:</b> <span>{ bookingResult.activity.status }</span>
                                                </div>
                                                <div>
                                                    <b>Confirmation Number:</b> <span>{ bookingResult.activity.confirmationNumber }</span>
                                                </div>
                                                <div>
                                                    <b>Customer Name:</b> <span>{ bookingResult.activity.customerName }</span>
                                                </div>
                                                <div>
                                                    <b>Activity Name:</b> <span>{ bookingResult.activity.activities[0].activityName }</span>
                                                </div>
                                                <div>
                                                    <b>Activity Code:</b> <span>{ bookingResult.activity.activities[0].activityCode }</span>
                                                </div>
                                                <div>
                                                    <b>Activity Reference:</b> <span>{ bookingResult.activity.activities[0].activityReference }</span>
                                                </div>
                                                <div>
                                                    <b>Date From:</b> <span>{ bookingResult.activity.activities[0].dateFrom }</span>
                                                </div>
                                                <div>
                                                    <b>Date To:</b> <span>{ bookingResult.activity.activities[0].dateTo }</span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div> : null
                            } */}

                            { !eTicketFormPage && !savedBookingIdsList.includes(bookingResult._id) ? <div style={{ marginBottom: "10px" }}>
                                <a href="#" className="btn btn-sm bg-secondary radius" onClick={(e) => {
                                    e.preventDefault();
                                    saveToList();
                                }}>Save to list</a>
                            </div> : null }
                        </> : null}
                        <div style={{ borderTop: '1px dashed rgb(226, 226, 232)', padding: eTicketFormPage ? "0 5px" : "0", paddingTop: '10px' }}>
                            <h4 style={{ marginBottom: "10px" }}>Search booking:</h4>
                            <div className="form-search" style={{ marginBottom: "10px" }}>
                                <div className="form-group" style={{ position: 'relative', flex: 'initial' }}>
                                    <label>Id:</label>
                                    <input
                                        type="text" className="input" placeholder="Input booking Id"
                                        value={searchValue} onInput={(e) => { setSearchValue(e.target.value); }} 
                                    />
                                </div>
                                <div className="form-group-actions">
                                    <button className="btn bg-secondary btn-rounded btn-rounded-sm" onClick={(e)=>{
                                        e.preventDefault();
                                        fetchBooking(searchValue);
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M20 20L16.3763 16.3763" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M4 11.25C4 15.2541 7.24594 18.5 11.25 18.5C15.2541 18.5 18.5 15.2541 18.5 11.25C18.5 7.24594 15.2541 4 11.25 4V4C7.24606 4.00029 4.00029 7.24606 4 11.25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            { !bookingResult && savedBookingIdsList?.length ? <>
                                <h4 style={{ borderTop: '1px dashed rgb(226, 226, 232)', paddingTop: '10px' }}>
                                    Booking saved list:
                                </h4>
                                <ul style={{ marginBottom: "10px" }}>
                                    {
                                        savedBookingIdsList.map((item, index) => {
                                            return <li key={index}>
                                                <a href={`${eTicketFormPage ? 'e-ticket-form.html' : 'e-ticket.html'}?id=${item}&user_id=${searchUserIdParam}`}>{ item }</a>
                                            </li>
                                        })
                                    }
                                </ul>
                            </> : null }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AppETicket;

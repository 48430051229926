import React, { useEffect, useState } from 'react';
import ConfirmationCard from '../ConfirmationCard';

export const BookingResultCard = ({ bookingResult }) => {
    const [showAccommodationsTerms, setShowAccommodationsTerms] = useState(false);

    const getCurrencySymbol = (value) => {
        return value === 'USD' ? '$' : value;
    }
    
    return (
        <div className="card-body p-4 booking-result-card-body">
            {/* {
                bookingResult?.hotel ? <div
                    className="duffel-app" 
                    style={{
                        borderBottom: "1px dashed rgba(226, 226, 232, 1)",
                        paddingBottom: "10px", marginBottom: "10px",
                    }}
                >
                    <h3 className="card-result-main-title" style={{ fontSize: "20px", marginBottom: "10px" }}>Hotel booking result:</h3>
                    <ul>
                        <li
                            style={{
                                fontSize: "14px",
                                lineHeight: "18px",
                                background: "#F9F9F9",
                                padding: "12px 16px",
                                borderRadius: "8px"
                            }}
                        >
                            <div>
                                <div><b style={{ fontSize: "12px" }}>CheckInDate:</b> <span>{bookingResult.hotel_offer.offers[0].checkInDate}</span></div>
                                <div><b style={{ fontSize: "12px" }}>CheckOutDate:</b> <span>{bookingResult.hotel_offer.offers[0].checkOutDate}</span></div>
                                <div><b style={{ fontSize: "12px" }}>Price:</b> <span>{bookingResult.hotel_offer.offers[0].price.currency}{bookingResult.hotel_offer.offers[0].price.total}</span></div>
                                <div><b style={{ fontSize: "12px" }}>Room description:</b> <span>{bookingResult.hotel_offer.offers[0].room.description.text}</span></div>
                                { bookingResult.hotel_offer.offers[0].room?.typeEstimated?.bedType ? 
                                    <div><b style={{ fontSize: "12px" }}>BedType:</b> <span>{bookingResult.hotel_offer.offers[0].room.typeEstimated.bedType}</span></div> : null
                                }
                                { bookingResult.hotel_offer.offers[0].room?.typeEstimated?.beds ?
                                    <div><b style={{ fontSize: "12px" }}>Beds:</b> <span>{bookingResult.hotel_offer.offers[0].room.typeEstimated.beds}</span></div> : null
                                }
                                { bookingResult.hotel_offer.offers[0].room?.typeEstimated?.category ?
                                    <div><b style={{ fontSize: "12px" }}>Room category:</b> <span>{bookingResult.hotel_offer.offers[0].room.typeEstimated.category.split("_").join(" ")}</span></div> : null
                                }
                            </div>
                        </li>
                    </ul>
                    <p style={{ textAlign: "center", marginTop: "10px" }}>Booking reference: <strong>{bookingResult.hotel.data[0].associatedRecords[0].reference}</strong></p>
                </div> : null
            } */}
            {
                bookingResult?.hotel?.accommodation?.location ? <div
                    className="duffel-app" 
                    style={{
                        borderBottom: bookingResult?.flight ? "1px dashed rgba(226, 226, 232, 1)" : "0",
                        paddingBottom: bookingResult?.flight ? "10px" : "0",
                        marginBottom: bookingResult?.flight ? "10px" : "0",
                    }}
                >
                    <h3 className="card-result-main-title" style={{ fontSize: "20px", marginBottom: "10px" }}>Hotel booking result:</h3>
                    <ul>
                        <li
                            style={{
                                fontSize: "14px",
                                lineHeight: "18px",
                                background: "#F9F9F9",
                                padding: "12px 16px",
                                borderRadius: "8px"
                            }}
                        >
                            <div>
                                <div>
                                    <b className="fsz-12">Reference:</b> <span>{ bookingResult.hotel.reference }</span>
                                </div>
                                <div><b className="fsz-12">Rooms:</b> <span>{ bookingResult.hotel.accommodation?.rooms?.length }</span></div>
                                <div><b className="fsz-12">Guests:</b> <span>{ bookingResult.hotel.guests?.length }</span></div>
                                <div>
                                    <b className="fsz-12">Confirmed:</b> <span>{ new Date(bookingResult.hotel.confirmed_at).toLocaleDateString() } at { new Date(bookingResult.hotel.confirmed_at).toLocaleTimeString() }</span>
                                </div>

                                <hr style={{ height: "1px", background: "#efefef", margin: "10px 0" }} />
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    { bookingResult?.hotel?.accommodation?.photos?.[0]?.url ? <div style={{ marginRight: '15px', display: 'flex', alignItems: 'center' }}>
                                        <img src={ bookingResult.hotel.accommodation.photos[0].url } alt="" style={{ maxWidth: "120px", maxHeight: "120px" }} />
                                    </div> : null }
                                    <div>
                                        <div>
                                            <b className="fsz-12">Name:</b> <span>{ bookingResult.hotel.accommodation.name }</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <b className="fsz-12">Rating:</b> 
                                            <ul className="rating-stars ml-10">
                                                {
                                                    new Array(5).fill('').map((itemRating, itemRatingIndex)=>{
                                                        return <li key={itemRatingIndex}>
                                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7.00002 1.16699L8.80252 4.81866L12.8334 5.40783L9.91669 8.24866L10.605 12.262L7.00002 10.3662L3.39502 12.262L4.08335 8.24866L1.16669 5.40783L5.19752 4.81866L7.00002 1.16699Z" fill={ bookingResult.hotel.accommodation.ratings[0].value >= itemRatingIndex+1 ? '#226A72' : '#AFB1B6' } />
                                                            </svg>
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        <div>
                                            <b className="fsz-12">Address:</b> <span>{ bookingResult.hotel.accommodation.location.address.city_name }, { bookingResult.hotel.accommodation.location.address.line_one }, { bookingResult.hotel.accommodation.location.address.postal_code }</span>
                                        </div>
                                    </div>
                                </div>

                                <hr style={{ height: "1px", background: "#efefef", margin: "10px 0" }} />

                                <div><b className="fsz-12">Check In Date:</b> <span>{new Date(bookingResult.hotel.check_in_date).toLocaleDateString()}</span></div>
                                <div><b className="fsz-12">Check Out Date:</b> <span>{new Date(bookingResult.hotel.check_out_date).toLocaleDateString()}</span></div>

                                <hr style={{ height: "1px", background: "#efefef", margin: "10px 0" }} />

                                {/* <div><b className="fsz-12">Price:</b> <span>{bookingResult.hotel.accommodation.rooms[0].rates[0].total_currency}{bookingResult.hotel.accommodation.rooms[0].rates[0].total_amount}</span></div> */}
                                <div><b className="fsz-12">Description:</b> <span>{bookingResult.hotel.accommodation.description}</span></div>

                                <hr style={{ height: "1px", background: "#efefef", margin: "10px 0" }} />

                                <h3 style={{ fontSize: "18px" }}>Cancellation policy:</h3>
                                
                                {
                                    bookingResult.hotel.accommodation.rooms[0]?.rates[0]?.cancellation_timeline?.map((item, index)=>{
                                        return <div key={index} style={{ marginBottom: "5px" }}>
                                            {
                                                +item.refundAmount === +bookingResult.hotel.accommodation.rooms[0].rates[0].total_amount &&
                                                <span><b style={{ display: "inlineFlex", alignItems: "center" }}>
                                                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "5px" }}>
                                                        <line x1="0.707107" y1="5.29289" x2="5.70711" y2="10.2929" stroke="#68B954" stroke-width="2"/>
                                                        <line x1="14.7071" y1="0.707106" x2="4.70712" y2="10.7071" stroke="#68B954" stroke-width="2"/>
                                                    </svg>
                                                    Full refund
                                                </b> - You may cancel for free before { new Date(item.before).toLocaleDateString() }, {new Date(item.before).toLocaleTimeString().slice(0, 5)}. You will be refunded the full amount.</span>
                                            }
                                            {
                                                +item.refundAmount !== +bookingResult.hotel.accommodation.rooms[0].rates[0].total_amount &&
                                                <span><b style={{ display: "inlineFlex", alignItems: "center" }}>
                                                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "5px" }}>
                                                        <line x1="0.707107" y1="5.29289" x2="5.70711" y2="10.2929" stroke="#EFBF13" stroke-width="2"/>
                                                        <line x1="14.7071" y1="0.707106" x2="4.70712" y2="10.7071" stroke="#EFBF13" stroke-width="2"/>
                                                    </svg>
                                                    Partial refund
                                                </b> - If you cancel before { new Date(item.before).toLocaleDateString() }, {new Date(item.before).toLocaleTimeString().slice(0, 5)} you will be refunded {getCurrencySymbol(item.currency)}{item.refundAmount}.</span>
                                            }
                                        </div>
                                    })
                                }
                                {
                                    bookingResult.hotel.accommodation.rooms[0]?.rates[0]?.cancellation_timeline?.length ? 
                                    <div style={{ marginBottom: "5px" }}>
                                        <span><b style={{ display: "inlineFlex", alignItems: "center" }}>
                                            <svg width="16" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "5px" }}>
                                                <line x1="1.70711" y1="1.29289" x2="11.6568" y2="11.2426" stroke="#C61924" stroke-width="2"/>
                                                <line x1="1.29289" y1="11.2426" x2="11.2426" y2="1.2929" stroke="#C61924" stroke-width="2"/>
                                            </svg>
                                            No refund
                                        </b> - After { new Date(bookingResult.hotel.check_in_date).toLocaleDateString() }, {new Date(bookingResult.hotel.check_in_date).toLocaleTimeString().slice(0, 5)}, you will not be refunded</span>
                                    </div> : <div style={{ marginBottom: "5px" }}>
                                        <b>Non refundable</b>
                                    </div>
                                }

                                <div>
                                    <a href="#" style={{ textDecoration: "underline" }} onClick={(e)=>{
                                        e.preventDefault();
                                        setShowAccommodationsTerms((prevState) => !prevState);
                                    }}><b className="fsz-12">{showAccommodationsTerms ? 'Close' : 'Read'} accommodation's terms and conditions</b></a>
                                </div>

                                { showAccommodationsTerms ? 
                                    bookingResult.hotel.accommodation.rooms[0].rates[0]?.conditions?.map((item, index)=>{
                                        return <div key={index}><b className="fsz-12">{ item?.title }:</b> <span dangerouslySetInnerHTML={{__html: item?.description }}></span></div>
                                    }) : null
                                }

                                <hr style={{ height: "1px", background: "#efefef", margin: "10px 0" }} />
                                
                                <h3 style={{ fontSize: "18px" }}>Room:</h3>
                                <div><b className="fsz-12">Name:</b> <span>{ bookingResult.hotel.accommodation.rooms[0].name }</span></div>
                                <div><b className="fsz-12">Base Price:</b> <span>{ getCurrencySymbol(bookingResult.hotel.accommodation.rooms[0].rates[0].base_currency) }{ bookingResult.hotel.accommodation.rooms[0].rates[0].base_amount }</span></div>
                                <div><b className="fsz-12">Tax:</b> <span>{ getCurrencySymbol(bookingResult.hotel.accommodation.rooms[0].rates[0].tax_currency) }{ bookingResult.hotel.accommodation.rooms[0].rates[0].tax_amount }</span></div>
                                <div><b className="fsz-12">Fee amount:</b> <span>{ getCurrencySymbol(bookingResult.hotel.accommodation.rooms[0].rates[0].fee_currency) }{ bookingResult.hotel.accommodation.rooms[0].rates[0].fee_amount }</span></div>
                                <div><b className="fsz-12">Total Price:</b> <span>{ getCurrencySymbol(bookingResult.hotel.accommodation.rooms[0].rates[0].total_currency) }{ bookingResult.hotel.accommodation.rooms[0].rates[0].total_amount }</span></div>
                                <div><b className="fsz-12">Due At Accommodation:</b> <span>{ getCurrencySymbol(bookingResult.hotel.accommodation.rooms[0].rates[0].due_at_accommodation_currency) }{ bookingResult.hotel.accommodation.rooms[0].rates[0].due_at_accommodation_amount || 
                                '0.00' }</span></div>

                                <hr style={{ height: "1px", background: "#efefef", margin: "10px 0" }} />

                                <div>
                                    <a href="terms-and-conditions.html" target="_blank" style={{ textDecoration: "underline" }}><b className="fsz-12">Terms and conditions</b></a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div> : null
            }
            { bookingResult?.flight ? <div className="duffel-app">
                    <ConfirmationCard order={bookingResult?.flight} />
            </div> : null }
            { bookingResult?._id ?
                <p style={{ textAlign: "center", marginTop: "10px" }}>Save this booking id: <strong><a href={`e-ticket.html?id=${bookingResult?._id}`} target='_blank'>{ bookingResult?._id }</a></strong></p> : null
            }
        </div>
    );
}
import React, { useState, useEffect } from 'react';
import { apiUrl } from "../../config/index.js"
import { Card } from '../Card';
import { GENERIC_ERROR_MESSAGE } from '../constants';

import { useDebounce } from "../../helpers/useDebounce";

export const SearchStatusCard = ({ onSuccess, onError }) => {
    const [isFetching, setIsFetching] = useState(false);
    const [referenceId, setReferenceId] = useState('');

    const searchOrder = async() => {
        setIsFetching(true);

        try {
            const res = await fetch(`${apiUrl}/duffel/orders/${referenceId}`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const { data, errors } = await res.json();

            if (errors) {
                onError(
                    new Error(
                        Array.isArray(errors) ? errors[0].title : GENERIC_ERROR_MESSAGE
                    )
                );
                return;
            }

            if (!data.length) {
                onError(new Error(GENERIC_ERROR_MESSAGE));
                return;
            }

            onSuccess(data[0]);
        } catch (e) {
            onError(e instanceof Error ? e : new Error(GENERIC_ERROR_MESSAGE));
        }

        setIsFetching(false);
    };

    return (
        <>
            <h2>Search flight status by reference id</h2>

            <Card.Root>
                <Card.Content>
                    <div className="text-align-left card__content_column">
                        <Card.Text color="light">Order reference id:</Card.Text>
                        <input
                            type="text" className="input type-2" placeholder="booking ref"
                            value={referenceId} onInput={(e)=>{setReferenceId(e.target.value)}}
                        />
                    </div>
                </Card.Content>

                <div>
                    <Card.Text color="light"> </Card.Text>
                    <Card.Button onClick={searchOrder} disabled={isFetching}>
                        {isFetching ? 'Searching…' : 'Search'}
                    </Card.Button>
                </div>
            </Card.Root>
        </>
    );
};

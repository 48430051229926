import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import AppStatus from './AppStatus';
import AppCombinedPlatforms from './AppCombinedPlatforms';
import AppETicket from './AppETicket';
import AppPaymentIntents from './AppPaymentIntents';

// Free account token pk.73fd73be485a2607469e45185aadc99b
const token = 'pk.73fd73be485a2607469e45185aadc99b';
const getReverseGeoData = async(coords) => {
    const url = `https://us1.locationiq.com/v1/reverse?key=${token}&lat=${coords.latitude}&lon=${coords.longitude}&format=json&accept-language=en&normalizecity=1`;
    return await fetch(url).then(res => res.json()); 
}

const initAllStuff = (res, geoData) => {
    if(document.getElementById('duffel-root')){
        const root = ReactDOM.createRoot(document.getElementById('duffel-root'));
        root.render(
            <React.StrictMode>
                <App geo={res} data={geoData}/>
            </React.StrictMode>
        );
    }
    
    if(document.getElementById('duffel-root-status')){
        const rootStatus = ReactDOM.createRoot(document.getElementById('duffel-root-status'));
        rootStatus.render(
            <React.StrictMode>
                <AppStatus geo={res} data={geoData}/>
            </React.StrictMode>
        );
    }
    
    if(document.getElementById('combined-platforms-app')){
        const rootCombinedPlatforms = ReactDOM.createRoot(document.getElementById('combined-platforms-app'));
        rootCombinedPlatforms.render(
            <React.StrictMode>
                <AppCombinedPlatforms geo={res} data={geoData}/>
            </React.StrictMode>
        );
    }
    if(document.getElementById('combinet-platform-e-ticket')){
        const rootETicket = ReactDOM.createRoot(document.getElementById('combinet-platform-e-ticket'));
        rootETicket.render(
            <React.StrictMode>
                <AppETicket />
            </React.StrictMode>
        );
    }
    if(document.getElementById('payment-intents')){
        const rootPaymentIntents = ReactDOM.createRoot(document.getElementById('payment-intents'));
        rootPaymentIntents.render(
            <React.StrictMode>
                <AppPaymentIntents />
            </React.StrictMode>
        );
    }
}


if(document.getElementById('combinet-platform-e-ticket') || document.getElementById('payment-intents')){
    initAllStuff();
} else {
    navigator.geolocation.getCurrentPosition(async(res) => {
        const geoData = res && res.coords ? await getReverseGeoData(res.coords) : {};
        initAllStuff(res, geoData);
    }, (err) => {
        console.log('Denied', err);
        initAllStuff();
    })
}

// initAllStuff();
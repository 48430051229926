import React, { useState } from 'react';
import { Card } from '../Card';
import { formatCurrency, getAirlineLogoUrl } from '../../utils';
import { GENERIC_ERROR_MESSAGE } from '../constants';

export const OrderInfoCard = ({ order, onSuccess, onError }) => {

    return (
        <>
            {/* <h2>2/3 We have a result, now let’s book it.</h2> */}
            <Card.Root>
                <Card.Content>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', flex: '1 1 auto' }}>
                            <img
                                src={getAirlineLogoUrl(order.owner.iata_code)}
                                alt={order.owner.name}
                                width={24}
                                height={24}
                            />
                            <Card.Text color="dark">{order.owner.name}</Card.Text>
                            <Card.Text color="dark">ID: <b>{order.booking_reference}</b></Card.Text>
                            <Card.Text color="dark">{new Date(order.slices[0].segments[0].departing_at).toLocaleDateString() +', '+ new Date(order.slices[0].segments[0].departing_at).toLocaleTimeString()}</Card.Text>
                            <Card.Text className="offer-currency" color="dark">
                                <b>{formatCurrency(order.total_amount, order.total_currency)}</b>
                            </Card.Text>
                        </div>
                        <div style={{ marginTop: "6px", paddingTop: "10px", borderTop: "1px dashed rgba(226, 226, 232, 1)" }}>
                            <div className="form-group-columns">
                                <div className="form-group text-align-left">
                                    <h5 style={{ textAlign: "left", marginBottom: "15px", fontSize: "18px" }}>Departing:</h5>
                                    <p><b>Date:</b> { new Date(order.slices[0].segments[0].departing_at).toLocaleDateString() }</p>
                                    <p><b>Time:</b> { new Date(order.slices[0].segments[0].departing_at).toLocaleTimeString() }</p>
                                    <p><b>Departing airport:</b> { order.slices[0].segments[0].origin.name }</p>
                                    <p><b>Departing city:</b> { order.slices[0].segments[0].origin.city_name }</p>
                                    <p><b>Terminal:</b> { order.slices[0].segments[0].origin_terminal }</p>
                                    <p><b>Aircraft:</b> { order.slices[0].segments[0].aircraft.name }</p>
                                </div>
                                <div className="form-group text-align-left">
                                    <h5 style={{ textAlign: "left", marginBottom: "15px", fontSize: "18px" }}>Arriving:</h5>
                                    <p><b>Date:</b> { new Date(order.slices[0].segments[0].arriving_at).toLocaleDateString() }</p>
                                    <p><b>Time:</b> { new Date(order.slices[0].segments[0].arriving_at).toLocaleTimeString() }</p>
                                    <p><b>Arriving airport:</b> { order.slices[0].segments[0].destination.name }</p>
                                    <p><b>Arriving city:</b> { order.slices[0].segments[0].destination.city_name }</p>
                                    <p><b>Terminal:</b> { order.slices[0].segments[0].destination_terminal }</p>
                                </div>
                            </div>
                        </div>
                        {
                            order.passengers.map((passenger, index)=>{
                                return <div style={{ marginTop: "6px", paddingTop: "10px", borderTop: "1px dashed rgba(226, 226, 232, 1)" }}>
                                    { index === 0 ? <h3 style={{ textAlign: "left", marginBottom: "15px", fontSize: "18px" }}>Passengers:</h3> : null }
                                    <div className="form-group-columns">
                                        <div className="form-group text-align-left">
                                            <label>Family name:</label>
                                            <p>{ passenger.family_name }</p>
                                        </div>
                                        <div className="form-group text-align-left">
                                            <label>Given name:</label>
                                            <p>{ passenger.given_name }</p>
                                        </div>
                                    </div>
                                    <div className="form-group-columns">
                                        <div className="form-group text-align-left">
                                            <label>Born on:</label>
                                            <p>{ new Date(passenger.born_on).toLocaleDateString() }</p>
                                        </div>
                                        <div className="form-group text-align-left">
                                            <label>Gender:</label>
                                            <p>{ passenger.gender === 'f' ? 'Female' : 'Male' }</p>
                                        </div>
                                    </div>
                                    <div className="form-group-columns">
                                        <div className="form-group text-align-left">
                                            <label>Email:</label>
                                            <p>{ passenger.email }</p>
                                        </div>
                                        <div className="form-group text-align-left">
                                            <label>Phone number:</label>
                                            <p>{ passenger.phone_number }</p>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    

                </Card.Content>
            </Card.Root>
        </>
    );
};

import React, { useState } from 'react';
import { SearchStatusCard } from './components/status-page/SearchStatusCard';
import { OrderInfoCard } from './components/status-page/OrderInfoCard';

const ErrorDisplay = ({ error }) => (
  <div className="error">{error.message}</div>
);

function AppStatus({geo, data}) {
    console.log('AppStatus geo', geo, data);
    const [order, setOrder] = useState(null);

    const hasOrder = order && typeof order === 'object' && !(order instanceof Error);

    return (
        <div className="page">
            <div className="example-flow">
                <div className="container">
                    { !order && (
                        <SearchStatusCard
                            onSuccess={(order) => setOrder(order)}
                            onError={(e) => setOrder(e)}
                        />
                    ) }
                    { hasOrder && (
                        <OrderInfoCard
                            order={order}
                        />
                    ) }
                    { order instanceof Error && <ErrorDisplay error={order} /> }
                    { order && (
                        <button
                            className="example-flow__reset"
                            onClick={() => {
                                setOrder(null);
                            }}
                        >
                            Start new search
                        </button>
                    ) }
                </div>
            </div>
        </div>
    );
}

export default AppStatus;

import React, { useEffect, useState } from 'react';
import { apiUrl } from "../config/index.js"
import { Card } from './Card';
import { formatCurrency, getAirlineLogoUrl } from '../utils';
import { GENERIC_ERROR_MESSAGE } from './constants';

export const BookingCard = ({ offers, offer, setOffer, onSuccess, onError, setBookingData, flySubmited, setFlySubmited, passengerSettingsOnly = false, adults = 1, childs = 0, initialPassengers = null }) => {
    console.log('BookingCard offer:', offer);

    const [isFetching, setIsFetching] = useState(false);
    const [bookStep, setBookStep] = useState(1);
    const [passengers, setPassengers] = useState([]);

    console.log('BookingCard passengers:', passengers);
    
    const selectOfferAction = (offerItem) => {
        setOffer(offerItem);
        setBookStep(2)
    }

    const bookOffer = async () => {
        setIsFetching(true);
        if(!offer){
            return false;
        }

        const res = await fetch(`${apiUrl}/duffel/book`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                offerId: offer.id,
                passengers: passengers,
                currency: offer.total_currency,
                amount: offer.total_amount,
            }),
        });

        const { order, errors } = await res.json();

        setIsFetching(false);

        if (Array.isArray(errors)) {
            onError(new Error(errors[0].title));
            return;
        }

        if (!order) {
            onError(new Error(GENERIC_ERROR_MESSAGE));
            return;
        }

        onSuccess(order);
    };

    const changePassengerData = (index, field_name, value) => {
        console.log('index, field_name, value:', index, field_name, value);
        let copiedPassengers = JSON.parse(JSON.stringify(passengers));
        copiedPassengers[index][field_name] = value;

        console.log('copiedPassengers:', copiedPassengers);
    
        setPassengers(copiedPassengers);

        if(passengerSettingsOnly){
            setBookingData("flight", {
                offer,
                passengers: copiedPassengers,
            });
        }
    }

    const initData = () => {
        // if(passengers?.length === +adults){
        //     return false;
        // }

        console.log('======set DEFAULT=======');
        console.log('initialPassengers:', initialPassengers);

        if(initialPassengers){
            setPassengers(offer ? initialPassengers.map((item, index) => ({
                ...item,
                id: offer.passengers[index].id
            })) : initialPassengers);
            return false;
        }

        if(offer || passengerSettingsOnly){
            let defaultValue = {
                id: offer?.passengers?.[0]?.id || null,
                born_on: '1987-07-24',
                family_name: 'Earheart',
                given_name: 'Amelia',
                gender: 'f',
                title: 'ms',
                email: 'amelia@duffel.com',
                phone_number: '+442080160509',
            };
            
            let defaultArray = new Array(+adults).fill(defaultValue);

            setPassengers(defaultArray);

            if(passengerSettingsOnly){
                setBookingData("flight", {
                    offer,
                    passengers: defaultArray,
                });
            }
        }
    }

    useEffect(()=>{
        initData();
    },[offer, passengerSettingsOnly, adults])

    return (
        <>
            { offer && bookStep === 1 ? <h3 style={{ paddingTop: "10px", fontSize: "20px" }}>Flights search result:</h3> : null }
            { offer && bookStep === 2 ? <h3 style={{ paddingTop: "10px", fontSize: "20px" }}>Flight booking settings:</h3> : null }
            <Card.Root className={ bookStep === 1 ? 'flight-booking-step-1' : 'flight-booking-step-2'}>
                <Card.Content>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
                        { (offer && bookStep === 2 ? [offer] : offers)?.map((item, index)=>{
                            return <div className="airway-card-info-row" key={index}>
                            <img
                                src={getAirlineLogoUrl(item.owner.iata_code)}
                                alt={item.owner.name}
                                width={24}
                                height={24}
                            />
                            <span className="card__text card__text--dark" style={{ marginBottom: '0px' }}>{item.owner.name}</span>
                            
                            <span className="card__text card__text--dark airway-card-info-item">
                                Departing at:
                                <span style={{ fontWeight: "400" }}>{ new Date(item.slices[0].segments[0].departing_at).toLocaleString() }</span>
                            </span>
                            <span className="card__text card__text--dark airway-card-info-item">
                                Arriving at:
                                <span style={{ fontWeight: "400" }}>{ new Date(item.slices[0].segments[0].arriving_at).toLocaleString() }</span>
                            </span>

                            <span className="card__text card__text--dark airway-card-info-item">
                                Origin:
                                <span style={{ fontWeight: "400" }}>{ item.slices[0].segments[0].origin.iata_code }</span>
                            </span>
                            <span className="card__text card__text--dark airway-card-info-item">
                                Destination:
                                <span style={{ fontWeight: "400" }}>{ item.slices[0].segments[0].destination.iata_code }</span>
                            </span>
                            
                            <span className="card__text card__text--dark offer-currency">
                                <b>{formatCurrency(item.total_amount, item.total_currency)}</b>
                                <span className="card__text card__text--dark airway-card-info-item">
                                    Class:
                                    <span style={{ fontWeight: "400" }}>{ 
                                        item.slices[0].segments[0].passengers[0]?.cabin_class_marketing_name ?
                                        item.slices[0].segments[0].passengers[0].cabin_class_marketing_name :
                                        item.slices[0].segments[0].passengers[0].cabin_class.split('_').join(' ')
                                    }</span>
                                </span>
                            </span>

                            {bookStep === 1 ? <Card.Button
                                disabled={isFetching}
                                onClick={()=>{selectOfferAction(item)}}
                            >
                                Book
                            </Card.Button> : null }
                        </div>
                        })}
                        
                        { (bookStep === 2 && offer) || passengerSettingsOnly ?
                        <>
                            { passengers.length ? passengers.map((passenger, index)=>{
                                return <div style={{ marginTop: "0", paddingTop: "10px", borderTop: "1px dashed rgba(226, 226, 232, 1)", pointerEvents: flySubmited ? 'none' : 'initial' }} key={index}>
                                    { index === 0 ? <h3 style={{ textAlign: "left", marginBottom: "15px", fontSize: "18px" }}>Passengers:</h3> : null }
                                    <div className="form-group-columns">
                                        <div className="form-group">
                                            <label>Family name:</label>
                                            <input 
                                                type="text" className="input type-2" placeholder="family name" value={ passenger.family_name }
                                                onInput={(e)=>{
                                                    changePassengerData(index, "family_name", e.target.value)
                                                }}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Given name:</label>
                                            <input
                                                type="text" className="input type-2" placeholder="given name" value={ passenger.given_name }
                                                onInput={(e)=>{
                                                    changePassengerData(index, "given_name", e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group-columns">
                                        <div className="form-group" style={{ opacity: offer ? '0.5': '1', pointerEvents: offer ? 'none': 'initial' }}>
                                            <label>Born on:</label>
                                            <input 
                                                type="date" className="input type-2" placeholder="born date" value={ passenger.born_on }
                                                onInput={(e)=>{
                                                    changePassengerData(index, "born_on", e.target.value)
                                                }}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Gender:</label>
                                            <select
                                                className="input type-2"
                                                value={ passenger.gender }
                                                onInput={(e)=>{
                                                    changePassengerData(index, "gender", e.target.value)
                                                }}
                                            >
                                                <option value="f">Female</option>
                                                <option value="m">Male</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Title:</label>
                                            <select
                                                className="input type-2"
                                                value={ passenger.title }
                                                onInput={(e)=>{
                                                    changePassengerData(index, "title", e.target.value)
                                                }}
                                            >
                                                <option value="mr">MR</option>
                                                <option value="mrs">MRS</option>
                                                <option value="ms">MS</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group-columns">
                                        <div className="form-group">
                                            <label>Email:</label>
                                            <input
                                                type="text" className="input type-2" placeholder="email" value={ passenger.email }
                                                onInput={(e)=>{
                                                    changePassengerData(index, "email", e.target.value)
                                                }}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Phone number:</label>
                                            <input
                                                type="text" className="input type-2" placeholder="phone number" value={ passenger.phone_number } 
                                                onInput={(e)=>{
                                                    changePassengerData(index, "phone_number", e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }) : null }

                            { !flySubmited && !passengerSettingsOnly ?  <div className="form-group-columns">
                                <div className="form-group" style={{
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}>
                                    <a
                                        href="/#" className="btn btn-sm bg-secondary radius btn-min-w-140"
                                        style={{
                                            background: "#FFFFFF",
                                            color: "#226A72",
                                            border: "1px solid #e8e8e8"
                                        }} 
                                        onClick={(e)=>{
                                            e.preventDefault();
                                            setBookStep(1);
                                            setOffer(null);
                                            setFlySubmited(false);
                                        }}
                                    >Back</a>
                                    <a href="/#" className="btn btn-sm bg-secondary radius btn-min-w-140" onClick={(e)=>{
                                        e.preventDefault();
                                        setBookingData("flight", {
                                            offer,
                                            passengers,
                                        });
                                        setFlySubmited(true);
                                    }}>Submit</a>
                                </div>
                            </div> : null }
                        </> : null }
                    </div>
                </Card.Content>
            </Card.Root>
        </>
    );
};

import React, { useEffect, useState } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { DuffelPayments } from "../../node_modules/@duffel/components";

function AppPaymentIntents() {
    const urlParams = new URLSearchParams(window.location.search);
    const clientToken = urlParams.get('client_token');

    const [paymentIntentToken, setPaymentIntentToken] = useState(null);

    const duffelPaymentSuccess = (value) => {
        window.ReactNativeWebView.postMessage(JSON.stringify({ success: true }));
    }
    

    const duffelPaymentFailed = (error) => {
        window.ReactNativeWebView.postMessage(JSON.stringify({ success: false, error }));
        NotificationManager.error(error?.message);
    }

    useEffect(()=>{
        if(clientToken){
            setPaymentIntentToken(clientToken);
        }
    }, [clientToken])

    return (
        <section className="search-section combined-platforms w-100">
            <div className="container" style={{ display: "flex", justifyContent: "center" }}>
                {
                    paymentIntentToken ? 
                    <DuffelPayments
                        paymentIntentClientToken={paymentIntentToken}
                        onSuccessfulPayment={duffelPaymentSuccess}
                        onFailedPayment={duffelPaymentFailed}
                    /> : 
                    <h3>Dont have client_token for payment</h3>
                }
            </div>

            <NotificationContainer/>
        </section>
    );
}

export default AppPaymentIntents;

import * as React from 'react';
import cx from 'classnames';

const Root = ({ className, children }) => (
    <div className={cx('card', className)}>{children}</div>
);

const Content = ({ className, children }) => (
    <div className={cx('card__content', className)}>{children}</div>
);

const Text = ({ className, children, color = 'dark' }) => (
    <span className={cx('card__text', `card__text--${color}`, className)}>
        {children}
    </span>
);

const Highlight = ({ className, children, secondary }) => (
    <span
        className={cx(
            'card__highlight',
            { 'card__highlight--secondary': secondary },
            className
        )}
    >
        {children}
    </span>
);

const Select = ({ className, defaultValue, onSelect, options }) => (
    <select
        className={cx('card__select', className)}
        defaultValue={defaultValue}
        onChange={(event) => onSelect(event.target.value)}
    >
        {options.map(({ value, label }) => (
            <option value={value} key={value}>
                {label}
            </option>
        ))}
    </select>
);

const Button = ({ className, children, secondary, ...props }) => (
    <button
        className={cx(
            'card__btn',
            { 'card__btn--secondary': secondary },
            className
        )}
        {...props}
    >
        {children}
    </button>
);

export const Card = {
    Root,
    Button,
    Highlight,
    Select,
    Text,
    Content,
};

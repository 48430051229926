import React, { useEffect, useState } from 'react';

export const HotelOfferItem = ({ hotel, hotelOffer, selectedHotelOffer, setSelectedHotelOffer, setBookingData }) => {
    const [hotelOrderData, setHotelOrderData] = useState({
        "offerId": hotelOffer.id,
        "guests": [
            {
                "name": {
                    "title": "MR",
                    "firstName": "BOB",
                    "lastName": "SMITH"
                },
                "contact": {
                    "phone": "+33679278416",
                    "email": "bob.smith@email.com"
                }
            }
        ],
        "payments": [
            {
                "method": "creditCard",
                "card": {
                    "vendorCode": "VI",
                    "cardNumber": "4242424242424242",
                    "expiryDate": "2026-01"
                }
            }
        ]
    });

    const [submited, setSubmited] = useState(false);

    const changeGuestData = (index, key_name, field_name, value) => {
        let copiedGuests = [...hotelOrderData.guests];
        copiedGuests[index][key_name][field_name] = value;

        setHotelOrderData((prevState)=>{
            return {
                ...prevState,
                guests: copiedGuests,
            }
        })
    }

    const changeGuestPaymentsData = (index, key_name, field_name, value) => {
        let copiedPayments = [...hotelOrderData.payments];
        copiedPayments[index][key_name][field_name] = value;

        setHotelOrderData((prevState)=>{
            return {
                ...prevState,
                payments: copiedPayments,
            }
        })
    }

    useEffect(()=>{
        console.log('hotelOrderData:', hotelOrderData);
    },[hotelOrderData])

    return (
        <>
            <li
                style={{
                    fontSize: "14px",
                    lineHeight: "18px",
                    background: "#F9F9F9",
                    padding: "12px 16px",
                    borderRadius: "8px"
                }}
            >
                <div>
                    <div><b style={{ fontSize: "12px" }}>CheckInDate:</b> <span>{hotelOffer.checkInDate}</span></div>
                    <div><b style={{ fontSize: "12px" }}>CheckOutDate:</b> <span>{hotelOffer.checkOutDate}</span></div>
                    <div><b style={{ fontSize: "12px" }}>Price:</b> <span>{hotelOffer.price.currency}{hotelOffer.price.total}</span></div>
                    <div><b style={{ fontSize: "12px" }}>Room description:</b> <span>{hotelOffer.room.description.text}</span></div>
                    { hotelOffer.room?.typeEstimated?.bedType ?
                        <div><b style={{ fontSize: "12px" }}>BedType:</b> <span>{hotelOffer.room.typeEstimated.bedType}</span></div> : null
                    }
                    { hotelOffer.room?.typeEstimated?.beds ?
                        <div><b style={{ fontSize: "12px" }}>Beds:</b> <span>{hotelOffer.room.typeEstimated.beds}</span></div> : null
                    }
                    { hotelOffer.room?.typeEstimated?.category ?
                        <div><b style={{ fontSize: "12px" }}>Room category:</b> <span>{hotelOffer.room.typeEstimated.category.split("_").join(" ")}</span></div> : null
                    }
                </div>
                { hotel && !selectedHotelOffer ?
                    <a href="/#" className="btn btn-sm bg-secondary radius" style={{ marginTop: "15px" }} onClick={(e)=>{
                        e.preventDefault();
                        setSelectedHotelOffer({
                            hotel: hotel,
                            offers: [hotelOffer]
                        });
                    }}>Select Offer</a> :
                    <a
                        href="/#" onClick={(e)=>{ e.preventDefault(); }}
                        style={{ marginTop: "15px", pointerEvents: "none", opacity: "0.7" }}
                        className="btn btn-sm bg-secondary radius"
                    >Selected</a>
                }
            </li>
            { selectedHotelOffer ? <li className="hotel-offer-item-form" style={{ pointerEvents: submited ? 'none' : 'initial' }}>
                <h3>Guests:</h3>
                { hotelOrderData.guests.map((guest, guestIndex)=>{
                    return <div key={ guestIndex }>
                        <h4>Name:</h4>
                        <div className="form-group-columns">
                            <div className="form-group">
                                <label>First Name:</label>
                                <input
                                    type="text" className="input type-2" placeholder="First Name"
                                    value={ guest.name.firstName }
                                    onInput={(e)=>{
                                        changeGuestData(guestIndex, "name", "firstName", e.target.value);
                                    }} 
                                />
                            </div>
                            <div className="form-group">
                                <label>Last Name:</label>
                                <input
                                    type="text" className="input type-2" placeholder="Last Name"
                                    value={ guest.name.lastName }
                                    onInput={(e)=>{
                                        changeGuestData(guestIndex, "name", "lastName", e.target.value);
                                    }} 
                                />
                            </div>
                        </div>
                        <div className="form-group-columns">
                            <div className="form-group">
                                <label>Title:</label>
                                <select
                                    className="input type-2"
                                    value={ guest.name.title }
                                    onInput={(e)=>{
                                        changeGuestData(guestIndex, "name", "title", e.target.value);
                                    }} 
                                >
                                    <option value="MR">MR</option>
                                    <option value="MRS">MRS</option>
                                    <option value="MS">MS</option>
                                </select>
                            </div>
                        </div>
                        <h4>Contact:</h4>
                        <div className="form-group-columns">
                            <div className="form-group">
                                <label>Phone:</label>
                                <input
                                    type="text" className="input type-2" placeholder="phone"
                                    value={ guest.contact.phone }
                                    onInput={(e)=>{
                                        changeGuestData(guestIndex, "contact", "phone", e.target.value);
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label>Email:</label>
                                <input
                                    type="text" className="input type-2" placeholder="email"
                                    value={ guest.contact.email }
                                    onInput={(e)=>{
                                        changeGuestData(guestIndex, "contact", "email", e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                })}
                <h4>Payments:</h4>
                <div className="form-group-columns">
                    <div className="form-group">
                        <label>Card Number:</label>
                        <input
                            type="text" className="input type-2" placeholder="card number"
                            value={ hotelOrderData.payments[0].card.cardNumber }
                            onInput={(e)=>{
                                changeGuestPaymentsData(0, "card", "cardNumber", e.target.value);
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <label>Expiry Date:</label>
                        <input
                            type="text" className="input type-2" placeholder="expiry date"
                            value={ hotelOrderData.payments[0].card.expiryDate }
                            onInput={(e)=>{
                                changeGuestPaymentsData(0, "card", "expiryDate", e.target.value);
                            }}
                        />
                    </div>
                </div>
                { !submited ? <div className="form-group-columns">
                    <div className="form-group" style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "calc(100% - 10px)"
                    }}>
                        <a
                            href="/#" className="btn btn-sm bg-secondary radius"
                            style={{
                                minWidth: "140px",
                                background: "#FFFFFF",
                                color: "#226A72",
                                border: "1px solid #e8e8e8"
                            }} 
                            onClick={(e)=>{
                                e.preventDefault();
                                setSelectedHotelOffer(null);
                            }}
                        >Back</a>
                        <a href="/#" className="btn btn-sm bg-secondary radius" style={{ minWidth: "140px" }} onClick={(e)=>{
                            e.preventDefault();
                            setBookingData("hotel", hotelOrderData);
                            setSubmited(true);
                        }}>Submit</a>
                    </div>
                </div> : null }
            </li> : null }
        </>
    );
}
import React, { useEffect, useState } from 'react';
import { apiUrl, amadeusApiUrl } from "./config/index.js"
import { SearchCard } from './components/SearchCard';
import { BookingCard } from './components/BookingCard';
import ConfirmationCard from './components/ConfirmationCard';
import { countriesList, currenciesFullList } from './constants/index.js';
import Select from 'react-select';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useDebounce } from "./helpers/useDebounce";
import { HotelOfferItem } from "./components/combined-platforms/HotelOfferItem"
import { StaysBookingCard } from "./components/combined-platforms/StaysBookingCard"
import { LoaderSpinner } from "./components/combined-platforms/LoaderSpinner"
import { BookingResultCard } from "./components/combined-platforms/BookingResultCard"
import { ActivitesListCard } from "./components/combined-platforms/ActivitesListCard"

// import { DuffelAncillaries, DuffelPayments } from "duffel-components";
import { DuffelAncillaries, DuffelPayments } from "../../node_modules/@duffel/components";

const ErrorDisplay = ({ error }) => (
  <div className="error">{error.message}</div>
);
// Free account token pk.73fd73be485a2607469e45185aadc99b
const token = 'pk.73fd73be485a2607469e45185aadc99b';

const getAutoComplete = async(q) => {
    const url = `https://api.locationiq.com/v1/autocomplete?key=${token}&q=${q}&limit=5&dedupe=1&tag=place:city&accept-language=en`;
    return await fetch(url).then(res => res.json())
}

let timer;

function AppCombinedPlatforms({geo, data}) {
    const [autocomplete, setAutocomplete] = useState([]);
    const [inputCity, setInputCity] = useState('');
    const [travelnextCities, setTravelnextCities] = useState(null);

    const [offer, setOffer] = useState(null);
    const [offers, setOffers] = useState([]);
    const [order, setOrder] = useState(null);
    const [amadeusActivities, setAmadeusActivities] = useState(null);
    const [activitiesList, setActivitiesList] = useState(null);
    const [activitesListLoading, setActivitesListLoading] = useState(false);
    const [activityDetails, setActivityDetails] = useState(false);
    const [activitySubmited, setActivitySubmited] = useState(false);
    const [activityBookingResult, setActivityBookingResult] = useState(null);
    const [activityDetailsOpen, setActivityDetailsOpen] = useState(false);
    const [activityBookingData, setActivityBookingData] = useState([{
        "title": "Mr",
        "firstName": "John",
        "lastName": "Doe",
        "email": "test@gmail.com",
        "address": "wooden garden road",
        "zipcode": "000000",
        "telephone": "0123456789",
        // navigator.language.slice(-2) || 
        "countryCode": "IN"
    }]);

    const [activityChildsBookingData, setActivityChildsBookingData] = useState([{
        "title": "Mr",
        "firstName": "Johny",
        "lastName": "Doe",
        "dob": "2022-10-01"
    }]);

    const [trawexData, setTrawexData] = useState({
        sessionId: null,
        tokenId: null,
    });

    const [hotelsResorts, setHotelsResorts] = useState(false);
    const [activitesListOpen, setActivitesListOpen] = useState(false);
    const [citySearchTimes, setCitySearchTimes] = useState(0);
    const citySearchStart = useDebounce(citySearchTimes, 1000);
    const [hotelsOffers, setHotelsOffers] = useState([]);
    const [hotelsOffersPageActive, setHotelsOffersPageActive] = useState(1);
    const [stays, setStays] = useState([]);
    const [staysPage, setStaysPage] = useState(1);
    const [staysAllRates, setStaysAllRates] = useState(null);
    const [quoteForRate, setQuoteForRate] = useState(null);
    const [searchInputedData, setSearchInputedData] = useState({
        amadeusBearer: null,
        activity: null, 
        // {
        //     geoCode: { latitude: 0, longitude: 0 }
        // },
        find_flights: false,
        hotels_resorts: false,
        country_to: '',
        country_to_code: '',
        city: '',
        city_coordinates: {
            latitude: null,
            longitude: null,
        },
        from: new Date().toISOString().split("T")[0],
        to: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split("T")[0],
        price: '',
        duffel: {
            sort: '',
            origin: '',
            destination: '',
        },
        booking_data: {
            hotel: null,
            flight: null,
        },
        adults: 1,
        childs: 0,
        child_ages: [],
    });

    const [selectedHotelOffer, setSelectedHotelOffer] = useState(null);
    const [getDataLoading, setGetDataLoading] = useState(false);

    const [hotelSubmited, setHotelSubmited] = useState(false);
    const [flySubmited, setFlySubmited] = useState(false);

    const [bookingLoading, setBookingLoading] = useState(false);
    const [bookingResult, setBookingResult] = useState(null);

    const [bookActivityLoading, setBookActivityLoading] = useState(false);

    const [paymentIntents, setPaymentIntents] = useState(null);

    const [selectedRateData, setSelectedRateData] = useState(null);
    
    const [amountForIntent, setAmountForIntent] = useState(0);

    const [amountForIntentFlight, setAmountForIntentFlight] = useState(false);
    const [amountForIntentHotel, setAmountForIntentHotel] = useState(false);

    const [paymentIntentConfirmed, setPaymentIntentConfirmed] = useState(false);

    const [flightsSearchDone, setFlightsSearchDone] = useState(false);
    const [hotelsSearchDone, setHotelsSearchDone] = useState(false);

    const hasOffers = offers.length;
    const hasOffer = offer && typeof offer === 'object' && !(offer instanceof Error);
    const hasOrder = order && typeof order === 'object' && !(order instanceof Error);

    const selectClassNames = {
        control: (state) => 'form-search-select-default',
        dropdownIndicator: (state) => 'form-search-select-dropdownindicator',
        indicatorSeparator: (state) => 'form-search-select-indicator-separator',
        menu: (state) => 'form-search-select-menu',
        menuList: (state) => 'form-search-select-menu-list',
        option: (state) => {
            return state.isSelected ? 'form-search-select-option is-selected' : state.isFocused ? 'form-search-select-option is-focused' : 'form-search-select-option'
        },
    }

    const countryChange = async(option) =>{
        setSearchInputedData((prevState)=>{
            return {
                ...prevState,
                country_to: option.label,
                country_to_code: countriesList.find(countryFind => countryFind.name === option.label).country
            }
        })
    }
    

    const applyResult = async(value) => {
        if(timer) clearTimeout(timer);
        timer = setTimeout(async() => {
            const res = await getAutoComplete(value);
            setAutocomplete(res);
        }, 600)
    }

    const cityChange = async(value, clear=false) => {
        
        setSearchInputedData((prevState)=>{
            return { ...prevState, city: value }
        })
        setCitySearchTimes((prevState) => prevState + 1);

        if(!clear) {
            applyResult(value);
        } else {
            setAutocomplete([])
        }

        setInputCity(value);
    }

    const searchActivities = async() => {
        return false;

        setActivitiesList(null);
        setTrawexData({
            sessionId: null,
            tokenId: null,
        });

        if(
            !searchInputedData.city.length ||
            !searchInputedData.country_to_code.length ||
            !searchInputedData.from.length ||
            !searchInputedData.to.length
        ){
            return false;
        }

        var activitiesHeaders = new Headers();
        activitiesHeaders.append("Content-Type", "application/json");
    
        let paxes = {
            "adults": +searchInputedData.adults,
            "childs": +searchInputedData.childs,
        }

        if(searchInputedData.childs > 0) paxes.child_ages = [...searchInputedData.child_ages].map(item => +item);

        let cityName = searchInputedData.city;
        
        if(travelnextCities?.length && travelnextCities.find(item => item.name.toLowerCase().includes(searchInputedData.city.toLowerCase()))){
            cityName = travelnextCities.find(item => item.name.toLowerCase().includes(searchInputedData.city.toLowerCase())).name;
        }

        var activitiesRequestOptions = {
            method: 'POST',
            headers: activitiesHeaders,
            body: JSON.stringify({
                "ip_address": "ip_address",
                "access": "Test",
                "city_name": cityName,
                "country_code": searchInputedData.country_to_code,
                "from_date": searchInputedData.from,
                "to_date": searchInputedData.to,
                "language":"en",
                "requiredCurrency":"USD",
                "paxes": paxes
            }),
            redirect: 'follow'
        };
        
        setActivitesListLoading(true);
        await fetch(`${apiUrl}/combined-platform/get-activities`, activitiesRequestOptions)
        .then(response => response.json())
        .then(async(response) => {
            if(response?.activities?.length){
                // setAmadeusActivities(response.data);
                setActivitiesList(response.activities);
                setTrawexData({
                    sessionId: response.sessionId,
                    tokenId: response.tokenId,
                })
            } else {
                if(response.name === "Error" && response.code === "ETIMEDOUT"){
                    setActivitesListLoading(true);
                    await searchActivities();
                    setActivitesListLoading(false);
                }

                setAmadeusActivities(false);
                setActivitiesList(false);
                // if(response?.name === 'Error'){
                //     NotificationManager.error(response?.message);
                // }
            }
        }).catch(error => {
            console.log('error', error);
            setActivitiesList(false);
        });
        setActivitesListLoading(false);
    }

    const citySearch = async() => {
        if(searchInputedData.amadeusBearer === null){
            return;
        }

        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${searchInputedData.amadeusBearer}`);

        var requestOptions = {
            method: 'GET',
            "timeout": 0,
            headers: myHeaders,
            redirect: 'follow'
        };

        let citySearchResult = await fetch(`${amadeusApiUrl}/v1/reference-data/locations/cities?keyword=${searchInputedData.city}&countryCode=${searchInputedData.country_to_code}`, requestOptions)
        .then(response => response.json())
        .then(response => {
            return response;
        }).catch(error => console.log('error', error));

        if(citySearchResult?.warnings){

        } else if(citySearchResult?.data?.length){
            setSearchInputedData((prevState)=>{
                return { ...prevState, city_coordinates: {
                    latitude: citySearchResult?.data[0].geoCode.latitude,
                    longitude: citySearchResult?.data[0].geoCode.longitude
                } }
            })


            // await fetch(`${amadeusApiUrl}/v1/shopping/activities?latitude=${citySearchResult?.data[0].geoCode.latitude}&longitude=${citySearchResult?.data[0].geoCode.longitude}&radius=20`, requestOptions)
            // .then(response => response.json())
            // .then(response => {
            //     if(response?.data?.length){
            //         console.log('response?.data:', response.data);
            //         setAmadeusActivities(response.data);
            //     } else {
            //         console.log('Not found');
            //         setAmadeusActivities(false);
            //     }
            // }).catch(error => console.log('error', error));
            
            // await searchActivities();
        }
    }

    const getAuthBearer = async() => {
        await fetch(`${apiUrl}/combined-platform/get-amadeus-bearer`, {
            method: 'GET',
            redirect: 'follow'
        }).then(response => response.text())
        .then(result => {
            setSearchInputedData((prevState)=>{
                return { ...prevState, amadeusBearer: result }
            })
        }).catch(error => console.log('error', error));
    }

    const search = async() => {
        setOffers([]);
        setHotelsOffers([]);
        setStays([]);
        setStaysPage(1);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(searchInputedData),
            redirect: 'follow'
        };

        if(!searchInputedData.country_to.length){
            NotificationManager.error('Select country please');
            return false;
        }

        if(!searchInputedData.city.length){
            NotificationManager.error('Select city please');
            return false;
        }
        
        // if((hotelsResorts || searchInputedData.hotels_resorts) && (!searchInputedData.activity || searchInputedData.activity === null)){
        //     NotificationManager.error('Select activity please');
        //     return false;
        // }

        setGetDataLoading(true);
        let result = await fetch(`${apiUrl}/combined-platform/get-data`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result?.flights?.length){
                setOffers(result?.flights);
            }

            if(result?.hotels_offers?.length){
                setHotelsOffers(result?.hotels_offers);
            }

            if(result?.stays?.length){
                setStays(result.stays);
            }

            if(searchInputedData?.find_flights){
                setFlightsSearchDone(true);
            }
                
            if(searchInputedData?.hotels_resorts){
                setHotelsSearchDone(true);
            }
        })
        .catch(error => console.log('error', error));
        setGetDataLoading(false);
    }

    const staysFetchAllRates = async(id) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
                "id": id
            }),
            redirect: 'follow'
        };

        setGetDataLoading(true);
        
        let result = await fetch(`${apiUrl}/combined-platform/stays-fetch-all-rates`, requestOptions)
        .then(response => response.json())
        .then(response => {
            return response;
        }).catch(error => {
            console.log('error', error)
        });

        if(result?.errors){
            NotificationManager.error(result?.errors[0].message);
            setGetDataLoading(false);
            return false;
        }

        setStaysAllRates(result);

        setGetDataLoading(false);
    }

    const createQuoteForRate = async(rate_id, rateData) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
                "rate_id": rate_id
            }),
            redirect: 'follow'
        };

        setGetDataLoading(true);
        
        let result = await fetch(`${apiUrl}/combined-platform/create-quote-for-rate`, requestOptions)
        .then(response => response.json()).then(response => {
            return response;
        }).catch(error => console.log('error', error));

        if(result?.errors){
            NotificationManager.error(result?.errors[0].message);
            setGetDataLoading(false);
            return false;
        }

        if(result?.id){
            setQuoteForRate(result);
            setSelectedRateData(rateData);
        }

        // setSearchInputedData((prevState) => {
        //     return { 
        //         ...prevState,
        //         booking_data: {
        //             ...searchInputedData.booking_data,
        //             hotel: result
        //         }
        //     }
        // })

        setGetDataLoading(false);
    }

    const book = async() => {
        setBookingLoading(true);
        let testLet = false;
        if(testLet){
            console.log('BOOK PAYLOAD:', {
                amadeusBearer: searchInputedData.amadeusBearer,
                activity: searchInputedData.activity,
                flight_offer: offer,
                hotel_offer: selectedHotelOffer,
                booking_data: searchInputedData.booking_data,
            });
        } else {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({
                    amadeusBearer: searchInputedData.amadeusBearer,
                    activity: searchInputedData.activity,
                    flight_offer: offer,
                    hotel_offer: selectedHotelOffer,
                    booking_data: searchInputedData.booking_data,
                }),
                redirect: 'follow'
            };

            // console.log("book data:", {
            //     amadeusBearer: searchInputedData.amadeusBearer,
            //     activity: searchInputedData.activity,
            //     flight_offer: offer,
            //     hotel_offer: selectedHotelOffer,
            //     booking_data: searchInputedData.booking_data,
            // });
            // return;

            let result = await fetch(`${apiUrl}/combined-platform/book`, requestOptions)
            .then(response => response.json())
            .then(result => {
                return result;
                // if(result?.flights?.length){
                //     setOffers(result?.flights);
                // }

                // if(result?.hotels_offers?.length){
                //     setHotelsOffers(result?.hotels_offers);
                // }
            })
            .catch(error => console.log('error', error));

            if(result?.hotel?.errors || result?.data?.hotel?.errors || result?.errors?.[0]?.message){
                NotificationManager.error(result?.hotel?.errors[0].message|| result?.data?.hotel?.errors[0].message || result?.errors[0].message);
                setBookingLoading(false);
                setHotelSubmited(false);
                // return false;
            }

            setBookingResult(result?.data && result?._id ? {
                ...result?.data,
                _id: result?._id
            } : result);

            if(result?._id){
                let gettedStorage = localStorage.getItem('booking-list-ids');

                if(gettedStorage){
                    gettedStorage = JSON.parse(gettedStorage);
                } else {
                    gettedStorage = [];
                }
                gettedStorage.push(result?._id);
                localStorage.setItem('booking-list-ids', JSON.stringify(gettedStorage));
            }
        }

        setBookingLoading(false);
    }

    const setBookingData = async (typeData, dataValue) =>{
        setSearchInputedData((prevState) => {
            return { 
                ...prevState,
                booking_data: {
                    ...searchInputedData.booking_data,
                    [typeData]: dataValue
                }
            }
        });

        // let amountForIntent = 0; 

        // if(quoteForRate?.total_amount){
        //     amountForIntent += +quoteForRate?.total_amount;
        // }
        
        if(typeData === "hotel" && dataValue && selectedRateData){
            // amountForIntent += +selectedRateData?.total_amount;
            setAmountForIntent((prev)=>{
                return prev + +selectedRateData?.total_amount;
            });

            setAmountForIntentHotel(true);
        }

        if(typeData === "flight" && dataValue?.offer?.total_amount){
            // amountForIntent += +dataValue?.offer?.total_amount;
            setAmountForIntent((prev)=>{
                return prev + +dataValue?.offer?.total_amount;
            });
        
            setAmountForIntentFlight(true);
        }
    }

    const checkForCreateIntent = async() => {
        if(amountForIntent > 0){
            if( (searchInputedData.find_flights && searchInputedData.hotels_resorts) && (!amountForIntentFlight || !amountForIntentHotel) ){
                return false;
            }

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({
                    // "currency": typeData === "hotel" ? selectedRateData?.total_currency : dataValue?.offer?.total_currency,
                    "currency": "USD",
                    "amount": (amountForIntent).toFixed(2),
                }),
                redirect: 'follow'
            };

            let result = await fetch(`${apiUrl}/combined-platform/create-payment-intents`, requestOptions)
            .then(response => response.json()).then(response => {
                return response;
            }).catch(error => console.log('error', error));

            if(!result?.errors) {
                setPaymentIntents(result);
            }
        }
    }

    const changePassengerData = (index, field, value) => {
        let copiedData = [...activityBookingData];
        copiedData[index][field] = value;
        setActivityBookingData(copiedData);
    }

    const changePassengerChildData = (index, field, value) => {
        let copiedData = [...activityChildsBookingData];
        copiedData[index][field] = value;
        setActivityChildsBookingData(copiedData);
    }

    const getActivityDetails = async(activity) => {
        if(!activity){
            NotificationManager.error('Select activity please');
            return false;
        }

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
                "sessionId": trawexData.sessionId,
                "tokenId": trawexData.tokenId,
                "activityCode": activity.code,
                "option_code": activity.options[0].code
            }),
            redirect: 'follow'
        };
        let result = await fetch(`${apiUrl}/combined-platform/activities-details`, requestOptions)
            .then(response => response.json()).then(result => { return result;}).catch(error => console.log('error', error));
        // console.log('getActivityDetails result:', result);
        setActivityDetails(result.activity);
    }

    const bookActivity = async() => {
        setBookActivityLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        
        // let bodyData = {
        //     "sessionId": trawexData.sessionId,
        //     "tokenId": trawexData.tokenId,
        //     "clientReference": "ACT4409987743",
        //     "leadPassanger": activityBookingData[0],
        //     "activities": [
        //         {
        //             "rateKey": activityDetails.options[0].rates[0].rateDetails[0].rateKey,
        //             "from": searchInputedData.from,
        //             "to": searchInputedData.to,
        //             "paxes": {
        //                 "adults": {
        //                     "title": activityBookingData.map(itemM => itemM.title),
        //                     "firstName": activityBookingData.map(itemM => itemM.firstName),
        //                     "lastName": activityBookingData.map(itemM => itemM.lastName)
        //                 }
        //             },
        //             "answers": [],
        //         }
        //     ]
        // }

        let bodyData = {
            "session_id": trawexData.sessionId,
            "client_reference": "",
            "lead_passanger": {
                "title": activityBookingData[0].title,
                "first_name": activityBookingData[0].firstName,
                "last_name": activityBookingData[0].lastName,
                "email_id": activityBookingData[0].email,
                "phone": activityBookingData[0].telephone,
                "address": activityBookingData[0].address,
                "zip_code": activityBookingData[0].zipcode,
                "country_code": activityBookingData[0].countryCode
            },
            "activities": [
                {
                    "option_code": activityDetails.options[0].code,
                    "rate_key": activityDetails.options[0].rates[0].rateKey,
                    "from": searchInputedData.from,
                    "to": searchInputedData.to,
                    "paxes": {
                        "adult": {
                            // "title": activityBookingData.map(itemM => itemM.title),
                            "first_name": activityBookingData.map(itemM => itemM.firstName),
                            "last_name": activityBookingData.map(itemM => itemM.lastName),
                            "dob": activityBookingData.map(itemM => itemM.dob),
                        }
                    },
                    "answers": [
                        {
                            "question_code": "EMAIL",
                            "answer": activityBookingData[0].email
                        }
                    ]
                }
            ]
        }

        if(searchInputedData.childs > 0){
            bodyData.activities[0].paxes.child = {
                // "title": activityChildsBookingData.map(itemM => itemM.title),
                "first_name": activityChildsBookingData.map(itemM => itemM.firstName),
                "last_name": activityChildsBookingData.map(itemM => itemM.lastName),
                "dob": activityChildsBookingData.map(itemM => itemM.dob),
            }
        }

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(bodyData),
            redirect: 'follow'
        };

        let testLet = false;
        if(testLet){
            console.log('bookActivity data:', bodyData);
        } else {
            let result = await fetch(`${apiUrl}/combined-platform/book-activity`, requestOptions)
            .then(response => response.json())
            .then(result => {
                return result;
            }).catch(error => console.log('error', error));

            if(result?.data?.activity){
                setActivityBookingResult({
                    ...result.data.activity,
                    _id: result._id,
                    user_id: result.user_id,
                });
            } else {
                setActivityBookingResult(result);
            }
        }
        setBookActivityLoading(false);
    }

    // const getTravelnextCities = async() => {
    //     let result = await fetch(`${apiUrl}/combined-platform/travelnext-cities`, {
    //         method: 'GET',
    //         redirect: 'follow'
    //     }).then(response => response.json())
    //     .then(result => { return result;})
    //     .catch(error => console.log('error', error));

    //     setTravelnextCities(result?.cities);
    // }

    const resetActivityBooking = () => {
        // setActivityBookingResult(null);
        window.location.reload();
    }

    const duffelPaymentSuccess = (value) => {
        setSearchInputedData((prevState) => {
            return { 
                ...prevState,
                booking_data: {
                    ...searchInputedData.booking_data,
                    ...( searchInputedData.booking_data.hotel ? { 
                        hotel: {
                            ...searchInputedData.booking_data.hotel,
                            payment_intent_id: paymentIntents?.id
                        }
                    } : {}),
                    ...( searchInputedData.booking_data.flight ? {
                        flight: {
                            ...searchInputedData.booking_data.flight,
                            payment_intent_id: paymentIntents?.id
                        }
                    } : {})
                }
            }
        });

        setPaymentIntentConfirmed(true);

        setTimeout(() => {
            book();
        }, 1500);
    }

    const duffelPaymentFailed = (error) => {
        console.log('duffelPaymentFailed:', error);
        NotificationManager.error(error?.message);
    }

    useEffect(()=>{
        getAuthBearer();
        // if(travelnextCities === null) {
        //     getTravelnextCities();
        // }
    },[]);

    useEffect(() => {
        if(citySearchStart){
            citySearch();
        }
    }, [citySearchStart]);

    useEffect(()=>{
        setSearchInputedData((prevState) => {
            return {
                ...prevState,
                child_ages: Array(+searchInputedData.childs).fill().map(item => 1)
            }
        })

        // if(+searchInputedData.childs !== activityBookingData.length){
            setActivityChildsBookingData(
                Array(+searchInputedData.childs).fill().map(item => {return {
                    "title": "Mr",
                    "firstName": "Johny",
                    "lastName": "Doe",
                    "dob": "2022-10-01"
                }})
            );
        // }

        // if(searchInputedData.adults !== activityBookingData.length){
            setActivityBookingData(
                Array(+searchInputedData.adults).fill().map(item => {return {
                    "title": "Mr",
                    "firstName": "John",
                    "lastName": "Doe",
                    "email": "test@gmail.com",
                    "address": "wooden garden road",
                    "zipcode": "000000",
                    "telephone": "0123456789",
                    // navigator.language.slice(-2) || 
                    "countryCode": "IN",
                    "dob": "1995-10-01"
                }})
            );
        // }

        // searchActivities();
    },[searchInputedData.adults, searchInputedData.childs]);

    useEffect(()=>{
        checkForCreateIntent();
    }, [amountForIntentFlight, amountForIntentHotel])

    return (
        <section className="search-section combined-platforms">
            <div className="page">
                <div className="example-flow">
                    <div className="container">
                        <div className="card card-white-shadow">
                            { !bookingResult?.flight && !bookingResult?.hotel ? <>
                                <div className="card-body p-4">
                                    <form action="" className="form-search" id="form_1">
                                        <div className="form-group">
                                            <label>Country</label>
                                            <Select
                                                placeholder="Select Country"
                                                classNames={selectClassNames}
                                                isSearchable={true}
                                                options={countriesList.map(country => {
                                                    return { value: `${country.latitude} | ${country.longitude}`, label: country.name }
                                                })}
                                                onChange={(option)=>{
                                                    countryChange(option);
                                                    setInputCity("");
                                                }}
                                            />
                                        </div>
                                        <div className="form-group" style={{position: 'relative'}}>
                                            <label>City</label>
                                            <input type="text" 
                                                value={inputCity} 
                                                className="input" 
                                                placeholder="Input City Name" 
                                                onInput={(e)=>{
                                                    cityChange(e.target.value);
                                                }} 
                                            />

                                            { !!autocomplete.length && 
                                                <ul className="autocomplete-list card-white-shadow">
                                                    {
                                                        autocomplete.map((ac, i) => {
                                                            return (
                                                                <li 
                                                                    key={i} onClick={(e) => cityChange(ac.display_place, true)} 
                                                                    style={{ 
                                                                        opacity: getDataLoading || bookingLoading ? '0.7' : '1',
                                                                        pointerEvents: getDataLoading || bookingLoading ? "none" : "initial"
                                                                    }}
                                                                >
                                                                    <b>{ac.display_place}</b>
                                                                    <span>{ac.display_address}</span>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                  
                                                </ul>
                                            }
                                        </div>
                                        <div className="form-group mw-135">
                                            <label>From</label>
                                            <input type="date" min={new Date().toISOString().split("T")[0]} className="input" placeholder="Select date" value={searchInputedData.from} onChange={(e)=>{
                                                setSearchInputedData((prevState)=>{
                                                    return { ...prevState, from: e.target.value }
                                                })
                                            }} />
                                        </div>
                                        <div className="form-group mw-135">
                                            <label>To</label>
                                            <input type="date" min={new Date().toISOString().split("T")[0]} className="input" placeholder="Select date" value={searchInputedData.to} onChange={(e)=>{
                                                setSearchInputedData((prevState)=>{
                                                    return { ...prevState, to: e.target.value }
                                                })
                                            }} />
                                        </div>
                                        {/* <div className="form-group">
                                            <label>Scenery</label>
                                            <Select
                                                classNames={selectClassNames}
                                                options={[
                                                    { value: '', label: 'Select Scenery' },
                                                    { value: '1', label: 'Scenery 1' },
                                                    { value: '2', label: 'Scenery 2' },
                                                    { value: '3', label: 'Scenery 3' },
                                                    { value: '4', label: 'Scenery 4' }
                                                ]}
                                            />
                                        </div> */}
                                        <div className="form-group mw-142">
                                            <label>Price</label>
                                            <input 
                                                type="number" className="input price_input" placeholder="Your Budget"
                                                value={ searchInputedData.price }
                                                onChange={(e)=>{
                                                    setSearchInputedData((prevState)=>{
                                                        return { ...prevState, price: e.target.value }
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="form-group mw-80">
                                            <label>Adults</label>
                                            <input 
                                                type="number" className="input" placeholder="Adults"
                                                value={ searchInputedData.adults }
                                                onChange={(e)=>{
                                                    setSearchInputedData((prevState)=>{
                                                        return { ...prevState, adults: e.target.value }
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="form-group mw-80">
                                            <label>Children</label>
                                            <input 
                                                type="number" className="input" placeholder="Children"
                                                value={ searchInputedData.childs }
                                                onChange={(e)=>{
                                                    setSearchInputedData((prevState)=>{
                                                        return { ...prevState, childs: e.target.value }
                                                    })
                                                }}
                                            />
                                        </div>
                                        {/* <div className="form-group activities-select-wrpr">
                                            <label>Activities</label> */}
                                            
                                            {/* <Select
                                                placeholder={amadeusActivities === false ? "Not Found" : "Select Activities"}
                                                classNames={selectClassNames}
                                                isSearchable={true}
                                                options={
                                                    amadeusActivities !== null ? amadeusActivities !== false ? 
                                                    amadeusActivities.filter(itemF => itemF.bookingLink && itemF?.geoCode?.latitude).map(activity => {
                                                        return { value: activity, label: activity.name }
                                                    }) : [{ value: '', label: 'Not Found' }] : [{ value: '', label: 'Select Country and City' }]
                                                }
                                                onChange={(option)=>{
                                                    setSearchInputedData((prevState)=>{
                                                        return { ...prevState, activity: option.value }
                                                    })
                                                }}
                                            /> */}

                                            {/* <Select
                                                placeholder={activitiesList === null || activitiesList === false ? "Not Found" : "Select Activities"}
                                                classNames={selectClassNames}
                                                isSearchable={true}
                                                value={searchInputedData.activity?.code ? {value: searchInputedData.activity?.code, label: searchInputedData.activity?.name} : null}
                                                options={
                                                    activitiesList !== null ? activitiesList !== false ? 
                                                    activitiesList.map(activity => {
                                                        return { value: activity.code, label: activity.name }
                                                    }) : [{ value: '', label: 'Not Found' }] : [{ value: '', label: 'Select Country and City' }]
                                                }
                                                onChange={(option)=>{
                                                    getActivityDetails(activitiesList.find(itemF => itemF.code === option.value));
                                                    setSearchInputedData((prevState)=>{
                                                        return { ...prevState, activity: activitiesList.find(itemF => itemF.code === option.value) }
                                                    });
                                                }}
                                            />
                                        </div> */}
                                        {/* { !searchInputedData.find_flights && !searchInputedData.hotels_resorts ? */}
                                        <div className="form-group-actions">
                                            <button className="btn bg-secondary btn-rounded btn-rounded-sm" onClick={(e)=>{
                                                e.preventDefault();
                                                search();
                                            }}>
                                                <img src="img/search-big-left.svg" alt="" />
                                            </button>
                                        </div>
                                        {/* : null } */}
                                    </form>
                                    <div className="form-search w-100">
                                        <div className="form-group" style={{ flex: 'initial' }}>
                                            <label className="checkbox-input">
                                                <input type="checkbox" name="" id="" checked={searchInputedData.find_flights} onChange={(e)=>{
                                                    setSearchInputedData((prevState)=>{
                                                        return { ...prevState, find_flights: e.target.checked }
                                                    })
                                                }} />
                                                <span className="checkbox-icon"></span>
                                                <span className="checkbox-text">Find flights</span>
                                            </label>
                                        </div>
                                        <div className="form-group" style={{ flex: 'initial' }}>
                                            <label className="checkbox-input">
                                                <input type="checkbox" name="" id="" checked={searchInputedData.hotels_resorts} onChange={(e)=>{
                                                    setSearchInputedData((prevState)=>{
                                                        return { ...prevState, hotels_resorts: e.target.checked }
                                                    })
                                                }} />
                                                <span className="checkbox-icon"></span>
                                                <span className="checkbox-text">Hotels Resorts</span>
                                            </label>
                                        </div>
                                        {   searchInputedData.child_ages.length ? 
                                            <div className="form-group">
                                                <label>Child ages:</label>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                                    { searchInputedData.child_ages.map((item, index) => {
                                                        return <input
                                                            type="number" className="input" placeholder="Child age"
                                                            key={index} value={ item }
                                                            style={{ maxWidth: "40px", borderRight: "1px solid #dfe0ea", marginRight: "10px" }}
                                                            onChange={(e)=>{
                                                                let copiedData = [...searchInputedData.child_ages];
                                                                copiedData[index] = e.target.value;
                                                                setSearchInputedData((prevState)=>{
                                                                    return { ...prevState, child_ages: copiedData }
                                                                })
                                                                // searchActivities();
                                                            }}
                                                        />}) 
                                                    }
                                                </div>
                                            </div> : null
                                        }
                                    </div>
                                </div>
                                { searchInputedData.find_flights ? <div className="card-body p-4 pt-0" style={{ borderTop: "1px dashed #E7E7E7" }}>
                                    <div className="duffel-app">
                                        { !offers.length ?
                                            <>
                                                <h3 style={{ paddingTop: "10px", fontSize: "20px" }}>Flights search settings:</h3>
                                                <SearchCard
                                                    data={data}
                                                    inputedData={ searchInputedData }
                                                    setInputedData={ setSearchInputedData }
                                                    beforeSearch={() => setOrder(null)}
                                                    onSuccess={(offer) => setOffer(offer)}
                                                    onError={(e) => setOffer(e)}
                                                />
                                                <BookingCard
                                                    // offers={offers}
                                                    // offer={offer}
                                                    // onSuccess={(order) => {
                                                    //     setOrder(order);
                                                    // }}
                                                    // setOffer={(offerItem)=>{setOffer(offerItem)}}
                                                    // onError={(e) => {
                                                    //     setOrder(e);
                                                    //     setOffer(null);
                                                    // }}
                                                    adults={searchInputedData.adults}
                                                    childs={searchInputedData.childs}
                                                    passengerSettingsOnly={true}
                                                    setBookingData={setBookingData}
                                                    flySubmited={ flySubmited }
                                                    setFlySubmited={ setFlySubmited }
                                                    initialPassengers={ searchInputedData?.booking_data?.flight?.passengers }
                                                />
                                            </>
                                        : null }
                                        { hasOffers && !hasOrder ?
                                            <BookingCard
                                                offers={offers}
                                                offer={offer}
                                                onSuccess={(order) => {
                                                    setOrder(order);
                                                }}
                                                setOffer={(offerItem)=>{setOffer(offerItem)}}
                                                onError={(e) => {
                                                    setOrder(e);
                                                    setOffer(null);
                                                }}
                                                setBookingData={setBookingData}
                                                flySubmited={ flySubmited }
                                                setFlySubmited={ setFlySubmited }
                                                initialPassengers={ searchInputedData?.booking_data?.flight?.passengers }
                                            />
                                        : flightsSearchDone && !offers.length ? <>
                                            <h3 style={{ paddingTop: "10px", fontSize: "20px" }}>Flights not found</h3>
                                        </> : null }
                                        { offer instanceof Error && <ErrorDisplay error={offer} /> }
                                        { bookingResult?.flight instanceof Error && <ErrorDisplay error={bookingResult?.flight} /> }
                                        {/* { offer && (
                                            <button
                                                className="example-flow__reset"
                                                onClick={() => {
                                                    setOrder(null);
                                                    setOffer(null);
                                                }}
                                            >
                                                Start new search
                                            </button>
                                        ) } */}
                                    </div>
                                </div> : null }
                                { searchInputedData.hotels_resorts && stays.length ?
                                    <StaysBookingCard 
                                        searchInputedData={ searchInputedData }
                                        stays={ stays }
                                        staysPage={ staysPage }
                                        staysAllRates={ staysAllRates }
                                        setStaysAllRates={ setStaysAllRates }
                                        setStaysPage={ setStaysPage }
                                        createQuoteForRate={ createQuoteForRate }
                                        quoteForRate={ quoteForRate }
                                        setQuoteForRate={ setQuoteForRate }
                                        setBookingData={ setBookingData }
                                        staysFetchAllRates={ staysFetchAllRates }
                                        hotelSubmited={ hotelSubmited }
                                        setHotelSubmited={ setHotelSubmited }
                                    />
                                : hotelsSearchDone && !stays.length ? <>
                                    <h3 style={{ paddingTop: "10px", fontSize: "20px" }}>Hotels not found</h3>
                                </> : null}
                                { searchInputedData.hotels_resorts && hotelsOffers.length ? <div className="card">
                                    {
                                        (selectedHotelOffer ? [selectedHotelOffer] : hotelsOffers).map((item, index) => {
                                            return <div className="card-body p-4" style={{ borderTop: "1px dashed rgb(231, 231, 231)" }} key={index}>
                                                <h3 style={{ fontSize: "20px" }}>
                                                    Hotel: {item.hotel.name}
                                                    <a href={`https://www.google.com/maps/place/${item.hotel.name}/@${item.hotel.latitude},${item.hotel.longitude},18z`} target="_blank">
                                                        <svg width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M20 21.6666V21.6666C17.2383 21.6666 15 19.4283 15 16.6666V16.6666C15 13.905 17.2383 11.6666 20 11.6666V11.6666C22.7617 11.6666 25 13.905 25 16.6666V16.6666C25 19.4283 22.7617 21.6666 20 21.6666Z" stroke="#226A72" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M20 35C20 35 8.33333 25.4167 8.33333 16.6667C8.33333 10.2233 13.5567 5 20 5C26.4433 5 31.6667 10.2233 31.6667 16.6667C31.6667 25.4167 20 35 20 35Z" stroke="#226A72" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                    </a>
                                                </h3>
                                                <h4 style={{ padding: "7px 0" }}>Offers:</h4>
                                                <ul>
                                                    {
                                                        item.offers.map((hotelOffer, hotelOfferIndex) =>
                                                        <HotelOfferItem
                                                            key={ hotelOfferIndex }
                                                            hotel={ item.hotel }
                                                            hotelOffer={ hotelOffer }
                                                            selectedHotelOffer={ selectedHotelOffer }
                                                            setSelectedHotelOffer={ setSelectedHotelOffer }
                                                            setBookingData={setBookingData}
                                                        /> )
                                                    }
                                                </ul>
                                            </div>
                                        })
                                    }
                                </div> : null }
                                {/* { searchInputedData.find_flights || searchInputedData.hotels_resorts ?
                                    <div style={{ 
                                        display: 'flex', justifyContent: 'center', marginTop: '15px',
                                        paddingTop: '15px', borderTop: "1px dashed #E7E7E7"
                                    }}>
                                        <a href="/#" className="btn btn-sm bg-secondary radius" style={{ minWidth: "140px" }} onClick={(e)=>{
                                            e.preventDefault();
                                            search();
                                        }}>Search</a>
                                    </div> : null
                                } */}
                                {
                                    // searchInputedData.booking_data.hotel || 
                                    // hotelSubmited || 
                                    false && searchInputedData.booking_data.flight && flySubmited && !paymentIntentConfirmed ? 
                                    <div style={{ marginTop: '15px', padding: '15px', borderTop: "1px dashed #E7E7E7" }}>
                                        <h3 style={{ marginBottom: "5px", fontSize: "20px" }}>Your ticket will be booked after payment</h3>
                                        {/* <DuffelAncillaries
                                            offer_id="fixture_off_1"
                                            services={["bags", "seats", "cancel_for_any_reason"]}
                                            passengers={[
                                                {
                                                    id: "pas_0000AUde3KY1SptM6ABSfU",
                                                    given_name: "Mae",
                                                    family_name: "Jemison",
                                                    gender: "F",
                                                    title: "dr",
                                                    born_on: "1956-10-17",
                                                    email: "m.jemison@nasa.gov",
                                                    phone_number: "+16177562626",
                                                },
                                                {
                                                    id: "pas_0000AUde3KY1SptM6ABSfT",
                                                    given_name: "Dorothy",
                                                    family_name: "Green",
                                                    gender: "F",
                                                    title: "dr",
                                                    born_on: "1942-10-17",
                                                },
                                            ]}
                                            // eslint-disable-next-line
                                            onPayloadReady={console.log}
                                        /> */}
                                        {
                                            paymentIntents ? 
                                            <DuffelPayments
                                                paymentIntentClientToken={paymentIntents?.client_token}
                                                onSuccessfulPayment={duffelPaymentSuccess}
                                                onFailedPayment={duffelPaymentFailed}
                                            /> : 
                                            <h3>Loading...</h3>
                                        }
                                    </div> : null
                                }
                                {
                                    ( 
                                        ((searchInputedData.hotels_resorts && searchInputedData.find_flights) && (searchInputedData.booking_data.hotel && hotelSubmited && searchInputedData.booking_data.flight && flySubmited) ) || 
                                        ((searchInputedData.hotels_resorts && !searchInputedData.find_flights && searchInputedData.booking_data.hotel && hotelSubmited) || 
                                        (searchInputedData.find_flights && !searchInputedData.hotels_resorts && searchInputedData.booking_data.flight && flySubmited))
                                    ) && !paymentIntentConfirmed ? 
                                    <div style={{ marginTop: '15px', padding: '15px', borderTop: "1px dashed #E7E7E7" }}>
                                        { searchInputedData.hotels_resorts && searchInputedData.find_flights ?
                                            <h3 style={{ marginBottom: "10px", fontSize: "20px" }}>Your room and ticket will be booked after payment</h3> : searchInputedData.hotels_resorts ? 
                                            <h3 style={{ marginBottom: "10px", fontSize: "20px" }}>Your room will be booked after payment</h3> : searchInputedData.find_flights ? 
                                            <h3 style={{ marginBottom: "10px", fontSize: "20px" }}>Your ticket will be booked after payment</h3>
                                        : null }
                                        {
                                            paymentIntents ? 
                                            <DuffelPayments
                                                paymentIntentClientToken={paymentIntents?.client_token}
                                                onSuccessfulPayment={duffelPaymentSuccess}
                                                onFailedPayment={duffelPaymentFailed}
                                            /> : 
                                            <h3>Loading...</h3>
                                        }
                                    </div> : null
                                }
                                { (searchInputedData.booking_data.hotel || searchInputedData.booking_data.flight) && (hotelSubmited || flySubmited) ? 
                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px', padding: '15px', borderTop: "1px dashed #E7E7E7" }}>
                                        <a
                                            href="/#" className="btn btn-sm bg-secondary radius btn-min-w-140"
                                            style={{
                                                background: "#FFFFFF",
                                                color: "#226A72",
                                                border: "1px solid #e8e8e8"
                                            }} 
                                            onClick={(e)=>{
                                                e.preventDefault();
                                                setHotelSubmited(false);
                                                setFlySubmited(false);
                                            }}
                                        >Back</a>
                                        {/* { searchInputedData.booking_data.hotel ? 
                                        <a href="/#" className="btn btn-sm bg-secondary radius btn-min-w-140" onClick={(e) => {
                                            e.preventDefault();
                                            book();
                                        }}>Book</a> : null } */}
                                    </div> : null
                                }
                                { getDataLoading || bookingLoading ? <div style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '10px 0' }}>
                                    <LoaderSpinner />
                                </div> : null }
                            </> : 
                                <BookingResultCard
                                    bookingResult={ bookingResult }
                                />
                            }
                        </div>
                        {/* { amadeusActivities !== null && bookingResult === null ? 
                            <>
                                <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                                    <a href="/#" className={`activites-list-open-btn ${activitesListOpen ? 'active' : ''}`} onClick={(e)=>{
                                        e.preventDefault();
                                        setActivitesListOpen(!activitesListOpen)
                                    }}>
                                        Activites List 
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.33301 9.16669L10.9997 12.8334L14.6663 9.16669" stroke="#323232" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </a>
                                </div>
                                { activitesListOpen ?
                                <ul className="activities_list">
                                    { amadeusActivities === false ? 
                                        <li>Not Found</li> : 
                                        amadeusActivities.filter(itemF => itemF.bookingLink).map((activity, index) => <li key={index}>
                                            <a href={activity.bookingLink} target="_blank">{activity.name} <span>{
                                                activity?.price?.currencyCode && currenciesFullList[activity?.price?.currencyCode]?.symbol ? 
                                                    currenciesFullList[activity?.price?.currencyCode].symbol+(activity.price.amount || 0) : 
                                                    currenciesFullList[activity?.price?.currencyCode]?.symbol ? 
                                                    currenciesFullList[activity?.price?.currencyCode]?.symbol+'0' : '0'
                                            }</span></a>
                                        </li>)
                                    }
                                </ul> : null }
                            </>
                        : null } */}
                        {/* <ActivitesListCard
                            activitesListLoading={ activitesListLoading }
                            activitiesList={ activitiesList }
                            bookingResult={ bookingResult }
                            activitesListOpen={ activitesListOpen }
                            setActivitesListOpen={ setActivitesListOpen }
                            activityBookingResult={ activityBookingResult }
                            searchInputedData={ searchInputedData }
                            setSearchInputedData={ setSearchInputedData }
                            getActivityDetails={ getActivityDetails }
                            activityDetailsOpen={ activityDetailsOpen }
                            setActivityDetailsOpen={ setActivityDetailsOpen }
                            setActivityDetails={ setActivityDetails }
                            setActivitySubmited={ setActivitySubmited }
                            activitySubmited={ activitySubmited }
                            activityDetails={ activityDetails }
                            activityBookingData={ activityBookingData }
                            changePassengerData={ changePassengerData }
                            bookActivity={ bookActivity }
                            bookActivityLoading={ bookActivityLoading }
                            countriesList={ countriesList }
                            setActivityBookingData={ setActivityBookingData }
                            activityChildsBookingData={ activityChildsBookingData }
                            changePassengerChildData={ changePassengerChildData }
                            resetActivityBooking={ resetActivityBooking }
                        /> */}
                    </div>
                </div>
            </div>

            <NotificationContainer/>
        </section>
    );
}

export default AppCombinedPlatforms;
